import React from 'react'
import AboutInnBanContent from '../../Common/InnerBanner/AboutInnBanContent';
import { AboutInnerBanner } from '../../Common/InnerBanner/AboutInnerBanner';
import RightHandSide from '../../Common/Services/RightHandSide';
import HeadDesign from '../BlogContent/HeadDesign';
import HeadContent from '../BlogContent/HeadContent';
import Info from '../BlogContent/Info';
import InfoContent from '../BlogContent/InfoContent';
import List from '../BlogContent/List';
import ListContent from '../BlogContent/ListContent';

const Blog3 = () => {
    const {image, heading, pageName} = AboutInnBanContent.BlogDetails;
    const {bg, para, date, blogHeading} = HeadContent.blog_3;
    const {title, p} = InfoContent.Blog_3.one;
    const {title1, p1} = InfoContent.Blog_3.one1;
    const {title2, p2} = InfoContent.Blog_3.two;
    const {title22, p22} = InfoContent.Blog_3.two2;
    const {title3, p3} = InfoContent.Blog_3.three;
    const {title4, p4} = InfoContent.Blog_3.four;
    const {title5, p5} = InfoContent.Blog_3.conclusion;

    const {liss} = ListContent.Blog_3.L1;
    const {liss2} = ListContent.Blog_3.L2;
    const {liss3} = ListContent.Blog_3.L3;
    const {liss4} = ListContent.Blog_3.L4;
    

  return (
    <>
        <AboutInnerBanner image={image} heading={heading} pageName={pageName} />

        <div className="blog-area pt-100 pb-70">
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                   <HeadDesign bg={bg} para={para} date={date} blogHeading={blogHeading} />
                    <Info title={title} p={p} />
                    <List liss={liss} />
                    <Info title={title1} p={p1} />


                    <Info title={title2} p={p2} />
                    <List liss={liss2} />
                    <Info title={title22} p={p22} />

                    <Info title={title3} p={p3} />
                    <List liss={liss3} />

                    <Info title={title4} p={p4} />
                    <List liss={liss4} />

                    <Info title={title5} p={p5} />
                </div>
                <div className="col-lg-4">
                    <RightHandSide/>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Blog3