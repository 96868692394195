import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import SDevide from './Common/SDevide';
import SDevideData from './Common/SDevideData';
import AllDetails from '../Common/Services/AllDetails';
import AllDetailsData from '../Common/Services/AllDetailsData';
import { Helmet } from 'react-helmet';
import icon from '../../Assets/images/icons/logo.png'

const ForeignPatientCare = () => {
    const {greenHeading, head, video, pOne} = TopContextData.ForeignPatientCare;
    const {sdHeading, sdImg, SDdata} = SDevideData.ForeignPatientCare;
    const {AllDData} = AllDetailsData.ForeignPatientCare;
  return (
    <>
    <Helmet>
      <title>Best IVF and Surrogacy Services in Mumbai | ACME FERTILITY</title>
      <meta name='description' content='Experience world-class IVF and surrogacy at ACME FERTILITY, Mumbai, with expert care for international patients.' />
      <meta name='keywords' content='IVF clinic Mumbai, Surrogacy services Mumbai, International IVF treatment, Affordable IVF India, Fertility treatments Mumbai, Best IVF center in India, Egg donation services, Fertility specialists Mumbai, Patient care IVF, IVF for international patients, Chembur fertility clinic, Assisted reproductive technology, Infertility solutions Mumbai, IVF success rates, Comprehensive fertility care, International patient support, Legal assistance IVF, IVF medical records submission, Comfortable accommodation IVF patients, Post-treatment support IVF.' />
      <link rel="canonical" href="https://acmefertility.com/Programme/ForeignPatientCare" />
      <meta name="robots" content="Index, follow " />
      <link rel="icon" href={icon} />
    </Helmet>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <SDevide sdHeading={sdHeading} sdImg={sdImg} SDdata={SDdata}/>
    <AllDetails AllDData={AllDData} />
    </>
  )
}

export default ForeignPatientCare