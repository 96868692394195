import React from 'react'
// import { Homepage } from '../Components/Homepage/Homepage'
import { OurServices } from '../Components/OurServices/OurServices'
// import { Counter } from '../Components/Counter/Counter'
// import { TabComp } from '../Components/Homepage/TabComp'
import { VideoSection } from '../Components/Homepage/VideoSection'
// import { Testimonals } from '../Components/Homepage/Testimonals'
import GoogleReviews from '../Components/Homepage/GoogleReviews'
import WhyChooseUs from '../Components/Homepage/WhyChooseUs'
// import Homepage1 from '../Components/Homepage/Homepage1'
import Transition from '../Components/Transition'
import Counter2 from '../Components/Counter/Counter2'
import { Helmet } from 'react-helmet'
import Hero from '../Components/Homepage/Hero'
const Home = () => {
  return (
    <>
    <Helmet>
        <title>Best Fertility Care at ACME FERTILITY | Mumbai's Leading Clinic</title>
        <meta name="description" content='Best fertility treatments at ACME FERTILITY, Mumbai, offering personalized IUI, IVF, and advanced care for parenthood success.' />
        <meta name='keywords' content='acme fertility, ivf clinic, infertility solutions, gynecologist hospital, fertility clinic near me, ivf near me, iui0, artificial insemination, fertility specialist near me, iui treatment' />
        <link rel="canonical" href="https://www.acmefertility.com/" />
        <meta name="robots" content="Index, follow " />
    </Helmet>

    {/* <Homepage/> */}
    {/* <Homepage1/> */}
    <Hero/>
    <OurServices/>
    {/* <Counter/> */}
    <Counter2/>
    {/* <TabComp/> */}
    {/* <Testimonals/> */}
    <WhyChooseUs />
    <GoogleReviews/>
    <VideoSection/>

    </>
  )
}

export default Transition(Home)