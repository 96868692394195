import React from 'react'
import RightHandSide from '../../Common/Services/RightHandSide'
import AboutInnBanContent from '../../Common/InnerBanner/AboutInnBanContent';
import { AboutInnerBanner } from '../../Common/InnerBanner/AboutInnerBanner';
import HeadContent from '../BlogContent/HeadContent';
import HeadDesign from '../BlogContent/HeadDesign';
import Info from '../BlogContent/Info';
import List from '../BlogContent/List';
import InfoContent from '../BlogContent/InfoContent';
import ListContent from '../BlogContent/ListContent';

const Blog10 = () => {
    const {image, heading, pageName} = AboutInnBanContent.BlogDetails;
    const {bg, para, date, blogHeading} = HeadContent.blog_10;

    const {title, p} = InfoContent.Blog_10.one;
    const {title2, p2} = InfoContent.Blog_10.two;
    const {title3, p3} = InfoContent.Blog_10.three;
    const {title4, p4} = InfoContent.Blog_10.four;
    const {title5, p5} = InfoContent.Blog_10.five;
    const {Ctitle, Cp} = InfoContent.Blog_10.conclusion;

    const {liss} = ListContent.Blog_10.L1;
    const {liss2} = ListContent.Blog_10.L2;
    const {liss3} = ListContent.Blog_10.L3;
    const {liss4} = ListContent.Blog_10.L4;

  return (
    <>
    <AboutInnerBanner image={image} heading={heading} pageName={pageName} />

    <div className="blog-area pt-100 pb-70">
    <div className="container">
        <div className="row">
            <div className="col-lg-8">
            <HeadDesign bg={bg} para={para} date={date} blogHeading={blogHeading} />
            <Info title={title} p={p} />
            <List liss={liss} />
            
            <h4>How PCOS Affects Fertility</h4>
            <List liss={liss2} />

            <Info title={title2} p={p2} />
            <List liss={liss3} />
            <Info title={title3} p={p3} />

            <Info title={title4} p={p4} />
            <List liss={liss4} />

            <Info title={title5} p={p5} />
      
            <Info title={Ctitle} p={Cp} />
            
            </div>
            <div className="col-lg-4">
                <RightHandSide/>
            </div>
        </div>
    </div>
    </div>
    
    
    </>
  )
}

export default Blog10