import React from "react";
import { Link } from "react-router-dom";
// import gsap from "gsap";
// import { useGSAP } from "@gsap/react";
import Slider from "react-slick";

const Hero = () => {
  // const heroTitle = useRef()
  // const lEase = gsap.parseEase("linear")
  // useGSAP(() => {
  //   gsap.from(heroTitle.current, {
  //     x: -2000,
  //     duration: 1,
  //     opacity: 0,
  //     ease: lEase

  //   })
  // })

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false
  };

  return (
    <>
      <div className="slider-container">
        <Slider {...settings}>

          {data.map((d)=> (
            <div className="m-0">
            <div className="hero-section">
              <div className="back-img">
                <img src={d.bg1} alt="1" className="slide-img" />
              </div>
              <div className="hero-content">
                <div className="sub-title-hero">
                  <div className="hero-text">
                    <h2 className="hero-title">
                      {d.title}
                    </h2>
                  </div>
                  <div className="hero-btn mt-5">
                    <Link to="/Appointment" class="common-btn style1">
                      Book Appointment
                      <span>
                        <i class="bx bx-plus"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Hero;

const data = [
  
  {
    bg1: require("../../Assets/images/hero/4.png"),
    title: (
      <>
      Grow Your Family, <br/>
      Grow Your Hope
      </>
    )
  },
  {
    bg1: require("../../Assets/images/hero/2.png"),
    title: (
      <>
      Your Miracle <br />
      Our Mission
      </>
    )
  },
  {
    bg1: require("../../Assets/images/hero/3.png"),
    title: (
      <>
      Your Miracle <br />
      Our Mission
      </>
    )
  },
  {
    bg1: require("../../Assets/images/hero/1.png"),
    title: (
      <>
      Your Miracle <br />
      Our Mission
      </>
    )
  },
  {
    bg1: require("../../Assets/images/hero/5.png"),
    title: (
      <>
      Your Miracle <br />
      Our Mission
      </>
    )
  },
  {
    bg1: require("../../Assets/images/hero/6.png"),
    title: (
      <>
      Your Miracle <br />
      Our Mission
      </>
    )
  },
]