import { Link } from "react-router-dom";

const HeadContent = {
    blog_1: {
        bg: require('../../../Assets/images/blog/headDesign/1.png'),
        date: "25 Oct, 2024",
        blogHeading: "How to Prepare for Your First IVF Cycle: A Step-by-Step Guide",
        para:[
            {
                details: (
                    <>
                    <Link to="/Programme/IVF">In vitro fertilization (IVF)</Link>  is a major milestone for individuals and couples looking to start or expand their families. If you're about to embark on your first IVF cycle, you're likely feeling a mix of excitement, hope, and maybe even some anxiety. Understanding how to prepare can help ease your concerns and set you up for the best possible outcome.
                    </>
                )
            },  
            {
                details: "This step-by-step guide walks you through the key stages of preparing for your first IVF cycle, from physical and emotional readiness to organizing your medical appointments and financial planning."
            }
        ]
    },
    blog_2: {
        bg: require('../../../Assets/images/blog/headDesign/2.png'),
        date: "26 Oct, 2024",
        blogHeading: "The Role of Genetics in IVF: What You Should Know",
        para:[
            {
                details: (
                    <>
                     <Link to="/Programme/IVF">In vitro fertilization (IVF) </Link> has transformed the landscape of reproductive medicine, offering hope to individuals and couples facing infertility. While the process of In vitro fertilization IVF itself involves a series of intricate steps—from ovarian stimulation to embryo transfer—understanding the role of genetics is equally crucial. Genetics can significantly impact the success of IVF, the health of the resulting embryos, and the long-term well-being of potential offspring. This blog delves into the various aspects of genetics in IVF, providing insights into what prospective parents should know.

                    </>
                )
            }
        ]
    },
    blog_3: {
        bg: require('../../../Assets/images/blog/headDesign/3.png'),
        date: "28 Oct, 2024",
        blogHeading: "IVF and Age: When is the Best Time to Start?",
        para:[
            {
                details: (
                    <>
                     In vitro fertilization (IVF) has revolutionized reproductive medicine, offering hope to many couples struggling with infertility. As more individuals pursue this path to parenthood, a common question arises: <Link to="/Programme/IVF">When is the best time to start IVF? </Link>Understanding the interplay between age and fertility is crucial for making informed decisions about IVF, as age can significantly affect both fertility and the likelihood of a successful pregnancy.

                    </>
                )
            }
        ]
    },
    blog_4: {
        bg: require('../../../Assets/images/blog/headDesign/4.png'),
        date: "28 Oct, 2024",
        blogHeading: "The Latest Innovations in IVF Technology for 2024",
        para:[
            {
                details: (
                    <>
                     <Link to="/Programme/IVF">In vitro fertilization (IVF) </Link>has undergone remarkable advancements since its inception, continuously evolving to increase success rates and improve patient experiences. As we enter 2024, IVF technology is poised to bring even more innovative breakthroughs. From artificial intelligence (AI) to genetic screening, the latest innovations are transforming the fertility landscape, offering new hope to those struggling with infertility. In this blog, we explore the most cutting-edge technologies revolutionizing IVF in 2024 and how they are reshaping fertility treatments.
                    </>
                )
            }
        ]
    },
    blog_5: {
        bg: require('../../../Assets/images/blog/headDesign/5.png'),
        date: "30 Oct, 2024",
        blogHeading: "Importance of Mental Health Support During IVF Treatments",
        para:[
            {
                details: (
                    <>
                     <Link to="/Programme/IVF">In vitro fertilization (IVF) </Link> offers hope to individuals and couples facing infertility, but the process can be emotionally and mentally challenging. The journey to conception through IVF is filled with uncertainty, hormonal changes, and the stress of high expectations. This is why mental health support during IVF treatments is critical. Ensuring emotional well-being alongside physical health can improve the overall IVF experience and even contribute to better outcomes. <br/>
                     In this blog, we'll explore the emotional toll of IVF, the benefits of mental health support, and practical ways to manage stress during fertility treatments.
                    </>
                )
            }
        ]
    },
    blog_6: {
        bg: require('../../../Assets/images/blog/headDesign/6.png'),
        date: "30 Oct, 2024",
        blogHeading: "Egg Freezing vs. IVF: Which is the Better Option for You? ",
        para:[
            {
                details: (
                    <>
                     Deciding between egg freezing and <Link to="/Programme/IVF">In vitro fertilization (IVF) </Link> can feel overwhelming, especially when your reproductive health is at stake. Both procedures offer unique advantages and serve different purposes. To help you make an informed choice, this blog will guide you through the differences, benefits, and considerations of each option, helping you decide which might be the best route for your fertility journey.
                    </>
                )
            }
        ]
    },
    blog_7: {
        bg: require('../../../Assets/images/blog/headDesign/7.png'),
        date: "30 Oct, 2024",
        blogHeading: "Unexpected Challenges in IVF: How to Navigate Secondary Infertility",
        para:[
            {
                details: "Infertility can be a difficult journey for many individuals and couples, and for those facing secondary infertility, the experience can be equally, if not more, challenging. Secondary infertility refers to the inability to conceive or carry a pregnancy to term after previously having one or more children. Many people assume that once they've had a successful pregnancy, fertility struggles are behind them, but secondary infertility can present unexpected roadblocks. If you're navigating secondary infertility while considering or undergoing IVF, this blog will guide you through the challenges and provide insights on how to approach the process."

            }
        ]
    },
    blog_9: {
        bg: require('../../../Assets/images/blog/headDesign/9.png'),
        date: "02 Nov, 2024",
        blogHeading: "IVF Success Rates & Statistics: Understanding Your Chances",
        para:[
            {
                details: <>
                    <Link to="/Programme/IVF" className="blogLink">In Vitro Fertilization (IVF) </Link> has become a beacon of hope for many couples facing infertility. With advancements in technology and medical practices, IVF success rates have improved significantly over the years. Understanding these statistics is crucial for anyone considering IVF as a pathway to parenthood. In this blog, we'll explore IVF success rates, the factors influencing these rates, and what couples can do to enhance their chances of success.
                </>

            }
        ]
    },
    blog_10: {
        bg: require('../../../Assets/images/blog/headDesign/10.png'),
        date: "04 Nov, 2024",
        blogHeading: "PCOS and Fertility Explained: Understanding the Connection",
        para:[
            {
                details: <>
                    <Link to="/" className="blogLink">Polycystic Ovary Syndrome (PCOS) </Link>is a common hormonal disorder affecting women of reproductive age. It is characterized by a combination of symptoms that can include irregular menstrual cycles, excess androgen levels, and polycystic ovaries. One of the most significant impacts of PCOS is on fertility, making it a crucial topic for those trying to conceive. This blog will delve into the relationship between PCOS and fertility, the challenges it poses, and the available treatment options to help women with PCOS achieve their family planning goals.
                </>

            }
        ]
    },
    blog_11: {
        bg: require('../../../Assets/images/blog/headDesign/11.png'),
        date: "04 Nov, 2024",
        blogHeading: "What is a Frozen Embryo Transfer (FET)?",
        para:[
            {
                details: <>
                    <Link to="/Programme/EmbryoTransfer" className="blogLink">Frozen Embryo Transfer (FET) </Link>has become an increasingly popular option in assisted reproductive technology (ART). This procedure allows embryos to be cryopreserved (frozen) after an initial IVF cycle and transferred at a later date. FET offers flexibility, optimized timing, and enhanced success rates for many patients. Let’s explore what FET is, how it works, its benefits, and what to expect during the process.
                    <br />
                    <br />
                    A description of <Link to="/Programme/EmbryoTransfer" className="blogLink">Frozen Embryo Transfer (FET) </Link> FET is the procedure of thawing and transferring embryos from an earlier IVF round to the uterus. By being cryopreserved at temperatures below freezing, these embryos are essentially "paused" in their development. FET gives patients the choice to have their embryos transferred during a cycle that might be better for them emotionally and physically. 
                </>

            }
        ]
    },
    
}

export default HeadContent;