import React from 'react'

const Info = ({title, p}) => {
  return (
    <>
        <div className="test-details-card blog-head">
        
            <div className="test-details-content">
            <div className="details-div d-flex">
                <h4>{title}</h4>
            </div>
                <p className='para'>{p}</p>   
           
            </div>
        </div>
    </>
  )
}

export default Info