import React from 'react'
// import AboutInnBanContent from '../Components/Common/InnerBanner/AboutInnBanContent'
// import { AboutInnerBanner } from '../Components/Common/InnerBanner/AboutInnerBanner';
import ProgrameTabPage from '../Components/Programe/ProgrameTabPage';
import Transition from '../Components/Transition';
import { Helmet } from 'react-helmet';
import icon from '../Assets/images/icons/logo.png'


const Programe = () => {
    // const {image, heading, pageName} = AboutInnBanContent.Programe;

  return (
    <>
    <Helmet>
      <title>Best IVF & ICSI Treatment at Acme Fertility in Mumbai</title>
      <meta name='description' content='Best IVF and ICSI treatment in Mumbai at Acme Fertility, with expert specialists and personalized care. ' />
      <meta name='keywords' content='Ivf , ICSI , Best ivf hospital in Navi Mumbai , Best Ivf hospital' />
      <link rel="canonical" href="https://www.acmefertility.com/Programme/IVF" />
      <meta name="robots" content="Index, follow " />
      <link rel="icon" href={icon} />
    </Helmet>
      {/* <AboutInnerBanner image={image} heading={heading} pageName={pageName} />   */}
      <ProgrameTabPage />
    </>
  )
}

export default Transition(Programe) 