import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import AllDetailsData from '../Common/Services/AllDetailsData';
import AllDetails from '../Common/Services/AllDetails';
import { Helmet } from 'react-helmet';
import icon from '../../Assets/images/icons/logo.png'

const EmbryoCrypto = () => {
    const {greenHeading, head, video, pOne} = TopContextData.EmbryoCrypto;
    const {AllDData} = AllDetailsData.EmbryoCrypto;

  return (
    <>
    <Helmet>
      <title>ACME Fertility's Sperm Cryopreservation | Safe Storage</title>
      <meta name='description' content='Best sperm cryopreservation services for IVF. Safeguard fertility with secure storage and expert care.' />
      <meta name='keywords' content='in vitro fertilization, ivf process, ivf procedure, ivf pregnancy, ivf process step by step, in vitro fertilization cost, icsi ivf, ivf cost in mumbai, ivf procedure step by step, intrauterine insemination, iui procedure, iui treatment, ivf injections' />
      <link rel="canonical" href="https://acmefertility.com/Programme/EmbryoCryopreservation" />
      <meta name="robots" content="Index, follow " />
      <link rel="icon" href={icon} />
    </Helmet>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <AllDetails AllDData={AllDData} />
    
    </>
  )
}

export default EmbryoCrypto