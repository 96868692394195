import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import SDevide from './Common/SDevide';
import SDevideData from './Common/SDevideData';
import AllDetails from '../Common/Services/AllDetails';
import AllDetailsData from '../Common/Services/AllDetailsData';
import { Helmet } from 'react-helmet';
import icon from '../../Assets/images/icons/logo.png'

const OocyteFreezing = () => {
    const {greenHeading, head, video, pOne} = TopContextData.OocyteFreezing;
    const {sdHeading, sdImg, SDdata} = SDevideData.OocyteFreezing;
    const {AllDData} = AllDetailsData.OocyteFreezing;
  return (
    <>
    <Helmet>
      <title>Best Egg Freezing Process to Preserve Fertility Potential</title>
      <meta name='description' content='Best preserve fertility with egg freezing, offering future pregnancy options for women facing medical or personal delay.' />
      <meta name='keywords' content='' />
      <link rel="canonical" href="https://acmefertility.com/Programme/OocyteFreezing" />
      <meta name="robots" content="Index, follow " />
      <link rel="icon" href={icon} />
    </Helmet>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <SDevide sdHeading={sdHeading} sdImg={sdImg} SDdata={SDdata} />
    <div class="blog-card test-details-card"><h4>How is Egg Freezing Done?</h4></div>
    <AllDetails AllDData={AllDData} />
    </>
  )
}

export default OocyteFreezing