import React from 'react'
import { AboutInnerBanner } from '../../Components/Common/InnerBanner/AboutInnerBanner';
import AboutInnBanContent from '../../Components/Common/InnerBanner/AboutInnBanContent';
import RightHandSide from '../../Components/Common/Services/RightHandSide';
import TopContent from '../../Components/Common/Services/TopContent';
import TopContentTxt from '../../Components/Common/Services/TopContentTxt';
import DbDevideData from '../../Components/Common/Services/DbDevideData';
import DbDevide from '../../Components/Common/Services/DbDevide';
import DbListData from '../../Components/Common/Services/DbListData';
import DbList from '../../Components/Common/Services/DbList';
import AllDetailsData from '../../Components/Common/Services/AllDetailsData';
import AllDetails from '../../Components/Common/Services/AllDetails';
import Transition from '../../Components/Transition';
import { Helmet } from 'react-helmet';

const MaleInfertility = () => {
  const {image, heading, pageName} = AboutInnBanContent.MaleInfertility;
  const {banner, head, pOne, pTwo, highligth} = TopContentTxt.MaleInfertility;
  const {dbHeading, dbImg, DBdata} = DbDevideData.MaleInfertility;
  const {dlHeading, Dldata} = DbListData.MaleInfertility;
  const {AllDData} = AllDetailsData.MaleInfertility.up;
  const {AllDData2} = AllDetailsData.MaleInfertility.down;

  return (
    <>
    <Helmet>
      <title>Male Infertility Evaluation and Treatment Solutions</title>
      <meta name='description' content='Explore advanced tests and treatments for male infertility, including semen analysis and surgical sperm retrieval options' />
      <meta name='keywords' content='male infertility, azoospermia, epididymal aspiration, hormone imbalance test, vitamins to balance hormones for females, hormone imbalance in women, male fertility clinic' />
      <link rel="canonical" href="https://www.acmefertility.com/MaleInfertility" />
      <meta name="robots" content="Index, follow " />
    </Helmet>
    <AboutInnerBanner image={image} heading={heading} pageName={pageName} />

    <div className="blog-details-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <TopContent banner={banner} head={head} pOne={pOne} pTwo={pTwo} highligth={highligth} />
            <DbDevide dbHeading={dbHeading} dbImg={dbImg} DBdata={DBdata} />
            <DbList dlHeading={dlHeading} Dldata={Dldata} />
            <AllDetails AllDData={AllDData} />

            <div className='blog-card test-details-card'>
              <h4>DIFFERENT METHODS OF SURGICAL SPERM RETRIEVAL (PESA/MESA/TESA/TESE)</h4>
            </div>
            <AllDetails AllDData ={AllDData2} />
          </div>
          <div className="col-lg-4">
            <RightHandSide />
        </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Transition(MaleInfertility) 