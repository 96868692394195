import React from "react";
import IVF_ICSI from "./IVF_ICSI";
import { NavLink } from "react-router-dom";

const ProgrameTabPage = () => {

  return (
    <div className="featured-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span>Leading Care</span>
          <h2>All Programme</h2>
        </div>
        <div className="featured-tab-area">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <NavLink to="/Programme/IVF" className="nav-link active">IVF</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Programme/ICSI" className="nav-link">ICSI</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Programme/PRP" className="nav-link">PRP</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Programme/IUI" className="nav-link">IUI</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Programme/EmbryoTransfer" className="nav-link">Embryo Transfer</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Programme/AssistedHeching" className="nav-link">Assisted Heching</NavLink>
            </li>
            <li className="nav-item">
             <NavLink to="/Programme/PGS" className="nav-link">PGS</NavLink>
            </li>
            <li className="nav-item">
             <NavLink to="/Programme/MACS" className="nav-link">MACS</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Programme/GamateDonars" className="nav-link">Gamate Donars</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Programme/SemenCryopreservation" className="nav-link">Semen Cryopreservation</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Programme/EmbryoCryopreservation" className="nav-link">Embryo Cryopreservation</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Programme/ForeignPatientCare" className="nav-link">Foreign Patient Care</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Programme/SingleEmbryoTransfer" className="nav-link">Single Embryo Transfer</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Programme/LaserAssistedHatching" className="nav-link">Laser Assisted Hatching</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Programme/OocyteFreezing" className="nav-link">Oocyte Freezing</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Programme/PICSI" className="nav-link">PICSI</NavLink>
            </li>
          </ul>
          <IVF_ICSI />

          
        </div>
      </div>
    </div>
  );
};

export default ProgrameTabPage;
