import { Footer } from "./Components/HeaderFooter/Footer";
// import { Navbar } from "./Components/HeaderFooter/Navbar";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home";
import AdvancedTechnology from "./Pages/About/AdvancedTechnology";
import AffordableTreat from "./Pages/About/AffordableTreat";
import Introduction from "./Pages/About/Introduction";
import PersonalizedCare from "./Pages/About/PersonalizedCare";
import QualityExpertise from "./Pages/About/QualityExpertise";
import RightEnvironment from "./Pages/About/RightEnvironment";
import Appointment from "./Pages/Appointment";
import Contact from "./Pages/Contact";
import Doctor from "./Pages/Doctor";
import LaparoscopyHysteroscopy from "./Pages/Services/LaparoscopyHysteroscopy";
import WomenHealthCare from "./Pages/Services/WomenHealthCare";
import FertilityCounselling from "./Pages/Services/FertilityCounselling";
import HormonalAssays from "./Pages/Services/HormonalAssays";
import FollicularMonitoring from "./Pages/Services/FollicularMonitoring";
import MaleInfertility from "./Pages/Services/MaleInfertility";
import ObstetricCare from "./Pages/Services/ObstetricCare";
import UrinaryIncontinenceManagement from "./Pages/Services/UrinaryIncontinenceManagement";
import FAQ from "./Pages/FAQ";
import Programe from "./Pages/Programe";
import Headroom from "react-headroom";
import ScrollToTop from "./Components/ScrollToTop";
import BackToTopButton from "./Components/BackToTopButton";
import { useEffect, useState } from "react";
import PreLoader from "./Components/PreLoader";
import { AnimatePresence } from "framer-motion";
 
import './responsive.scss';
import Navbar2 from "./Components/HeaderFooter/Navbar2";
import ICSITab from "./Components/Programe/Tabs/ICSITab";
import PRPTab from "./Components/Programe/Tabs/PRPTab";
import IUITab from "./Components/Programe/Tabs/IUITab";
import EmbryoTransferTab from "./Components/Programe/Tabs/EmbryoTransferTab";
import AssistedHechingTab from "./Components/Programe/Tabs/AssistedHechingTab";
import PGSTab from "./Components/Programe/Tabs/PGSTab";
import MACSTab from "./Components/Programe/Tabs/MACSTab";
import GamateDonarsTab from "./Components/Programe/Tabs/GamateDonarsTab";
import SemenCryopreservationTab from "./Components/Programe/Tabs/SemenCryopreservationTab";
import EmbryoCryopreservationTab from "./Components/Programe/Tabs/EmbryoCryopreservationTab";
import ForeignPatientCareTab from "./Components/Programe/Tabs/ForeignPatientCareTab";
import SingleEmbryoTransferTab from "./Components/Programe/Tabs/SingleEmbryoTransferTab";
import LaserAssistedHatchingTab from "./Components/Programe/Tabs/LaserAssistedHatchingTab";
import OocyteFreezingTab from "./Components/Programe/Tabs/OocyteFreezingTab";
import PICSITab from "./Components/Programe/Tabs/PICSITab";
import BlogPage from "./Pages/BlogPage";
import Blog1 from "./Components/Blogs/BlogPages/Blog1";
import Blog2 from "./Components/Blogs/BlogPages/Blog2";
import Blog3 from "./Components/Blogs/BlogPages/Blog3";
import Blog4 from "./Components/Blogs/BlogPages/Blog4";
import Blog5 from "./Components/Blogs/BlogPages/Blog5";
import Blog6 from "./Components/Blogs/BlogPages/Blog6";
import Blog7 from "./Components/Blogs/BlogPages/Blog7";
import { Helmet } from "react-helmet";
import logo from './Assets/images/icons/logo.png'
import Blog9 from "./Components/Blogs/BlogPages/Blog9";
import Blog10 from "./Components/Blogs/BlogPages/Blog10";
import Blog11 from "./Components/Blogs/BlogPages/Blog11";

function App() {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(()=>{
      setTimeout(()=>{
        setIsLoading(false)
      }, 2000)
    }, [])
  return (
    
      isLoading ? (<PreLoader/>) :
     ( 
     
     <>
      <Helmet>
        <link rel="icon" href={logo} />
      </Helmet>
      <ScrollToTop/>
      <Headroom>
        {/* <Navbar/> */}
        <Navbar2/>
      </Headroom>
          <AnimatePresence mode='wait'>
          <Routes basename = "/" location={location} key={location.pathname}>
            <Route path="/" index element={<Home/>} />

            <Route path="/AdvancedTechnology" element={<AdvancedTechnology/>} />
            <Route path="/AffordableTreatment" element={<AffordableTreat/>} />
            <Route path="/Introduction" element={<Introduction/>} />
            <Route path="/PersonalizedCare" element={<PersonalizedCare/>} />
            <Route path="/QualityExpertise" element={<QualityExpertise/>} />
            <Route path="/RightEnvironment" element={<RightEnvironment/>} />

            <Route path="/Appointment" element={<Appointment/>} />
            <Route path="/Contact" element={<Contact/>} />
            <Route path="/Doctor" element= {<Doctor/>} />
            
            <Route path="/LaparoscopyHysteroscopy" element= {<LaparoscopyHysteroscopy/>} />
            <Route path="/WomenHealthCare" element= {<WomenHealthCare/>} />
            <Route path="/FertilityCounselling" element= {<FertilityCounselling/>} />
            <Route path="/HormonalAssays" element= {<HormonalAssays/>} />
            <Route path="/FollicularMonitoring" element= {<FollicularMonitoring/>} />
            <Route path="/MaleInfertility" element= {<MaleInfertility/>} />
            <Route path="/ObstetricCare" element= {<ObstetricCare/>} />
            <Route path="/UrinaryIncontinenceManagement" element= {<UrinaryIncontinenceManagement/>} />

            <Route path="/FAQ" element={<FAQ/>} />

            <Route path="/Programme/IVF" element={<Programe/>} />

            <Route path="/Programme/ICSI" element= {<ICSITab/>} />
            <Route path="/Programme/PRP" element={<PRPTab/>} />
            <Route path="/Programme/IUI" element={<IUITab/>} />
            <Route path="/Programme/EmbryoTransfer" element={<EmbryoTransferTab/>} />
            <Route path="/Programme/AssistedHeching" element={<AssistedHechingTab/>} />
            <Route path="/Programme/PGS" element={<PGSTab/>} />
            <Route path="/Programme/MACS" element={<MACSTab/>} />
            <Route path="/Programme/GamateDonars" element={<GamateDonarsTab/>} />
            <Route path="/Programme/SemenCryopreservation" element={<SemenCryopreservationTab/>} />
            <Route path="/Programme/EmbryoCryopreservation" element={<EmbryoCryopreservationTab/>} />
            <Route path="/Programme/ForeignPatientCare" element={<ForeignPatientCareTab/>} />
            <Route path="/Programme/SingleEmbryoTransfer" element={<SingleEmbryoTransferTab/>} />
            <Route path="/Programme/LaserAssistedHatching" element={<LaserAssistedHatchingTab/>} />
            <Route path="/Programme/OocyteFreezing" element={<OocyteFreezingTab/>} />
            <Route path="/Programme/PICSI" element={<PICSITab/>} />

            <Route path="/Blogs" element= {<BlogPage/>} />
            <Route path="/1/BlogDetails" element={<Blog1/>} />
            <Route path="/2/BlogDetails" element={<Blog2/>} />
            <Route path="/3/BlogDetails" element={<Blog3/>} />
            <Route path="/4/BlogDetails" element={<Blog4/>} />
            <Route path="/5/BlogDetails" element={<Blog5/>} />
            <Route path="/6/BlogDetails" element={<Blog6/>} />
            <Route path="/7/BlogDetails" element={<Blog7/>} />

            <Route path="/9/BlogDetails" element={<Blog9/>} />
            <Route path="/10/BlogDetails" element={<Blog10/>} />
            <Route path="/11/BlogDetails" element={<Blog11/>} />
            
          </Routes>
          </AnimatePresence>
        <Footer/>
      <BackToTopButton/>
      </>
     )
  );
}

export default App;
