import React from "react";

const List = ({liss}) => {
  return (
    <>
      <div class="bd-divide mb-2">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <div class="bd-list">
              <ul>
                {liss.map((i) => (
                  <li>
                    <i class="bx bx-log-in-circle"></i>
                    <b>{i.bold}</b>
                    {i.item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default List;
