import React from 'react';
import '../../../src/Style.scss'
import { AboutInnerBanner } from '../../Components/Common/InnerBanner/AboutInnerBanner';
import AboutInnBanContent from '../../Components/Common/InnerBanner/AboutInnBanContent';
import AboutContent from '../../Components/Common/AboutContent/AboutContent';
import AboutInfo from '../../Components/Common/AboutContent/AboutInfo';
import Transition from '../../Components/Transition';
import { Helmet } from 'react-helmet';

const Introduction = () => {

  const { bg, doctor, title, data } = AboutInfo.intruduction;
  
  return (
    <>
      <Helmet>
        <title>Best Fertility Care at ACME FERTILITY Chembur, Mumbai</title>
        <meta name="description" content="Best personalized fertility treatments at ACME FERTILITY, Mumbai, offering advanced care and high success rates." />
        <meta name='keywords' content='acme fertility chembur , personalized care ,ivf success rates by age, iui success rate by age ,in vitro fertilization success rate ,ivf cost and success rate ,ivf success ,rates over 40 ,ivf chances of success , most cost effective cloud storage' />
        <link rel="canonical" href="https://www.acmefertility.com/Introduction" />
        <meta name="robots" content="Index, follow " />
      </Helmet>

      <AboutInnerBanner 
        image={AboutInnBanContent.intruduction.image}
        heading={AboutInnBanContent.intruduction.heading}
        pageName={AboutInnBanContent.intruduction.pageName}
        />

      <AboutContent 
        bg= {bg}
        doctor= {doctor}
        title ={title}
        data ={data}
      />
    </>
  )
}

export default Transition(Introduction) 