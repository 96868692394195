import React from 'react'
import AboutContent from '../../Components/Common/AboutContent/AboutContent'
import { AboutInnerBanner } from '../../Components/Common/InnerBanner/AboutInnerBanner'
import AboutInfo from '../../Components/Common/AboutContent/AboutInfo'
import AboutInnBanContent from '../../Components/Common/InnerBanner/AboutInnBanContent'
import Transition from '../../Components/Transition'
import { Helmet } from 'react-helmet'

const QualityExpertise = () => {
  const {bg, doctor, title, data} = AboutInfo.qualityExpertise;
  const {image, heading, pageName} = AboutInnBanContent.qualityExpertise
  return (
    <>
      <Helmet>
        <title>Best IVF Care at ACME FERTILITY | Quality & Expertise</title>
        <meta name='description' content='ACME FERTILITY Chembur offers expert fertility treatments with a focus on quality, innovation, and high success rates.' />
        <meta name='keywords' content='fertility experts, patient centric approach , reproductive endocrinologists , fertility expert near me , best reproductive endocrinologist near me' />
        <link rel="canonical" href="https://www.acmefertility.com/QualityExpertise" />
        <meta name="robots" content="Index, follow " />
      </Helmet>

      <AboutInnerBanner image={image} heading={heading} pageName={pageName}/>
      <AboutContent bg={bg} doctor={doctor} title={title} data={data} />
    </>
  )
}

export default Transition(QualityExpertise) 