import React from 'react'
import { AboutInnerBanner } from '../../Components/Common/InnerBanner/AboutInnerBanner'
import AboutContent from '../../Components/Common/AboutContent/AboutContent'
import AboutInnBanContent from '../../Components/Common/InnerBanner/AboutInnBanContent'
import AboutInfo from '../../Components/Common/AboutContent/AboutInfo'
import Transition from '../../Components/Transition'
import { Helmet } from 'react-helmet'

const RightEnvironment = () => {
  const {image, heading, pageName} = AboutInnBanContent.RightEnvironment;
  const {bg, doctor, title, data} = AboutInfo.RightEnvironment;

  return (
    <>
    <Helmet>
      <title>Right Environment for Stress-Free Fertility Care</title>
      <meta name='description' content='Best homely and private IVF treatment at ACME Fertility. Experience compassionate care in a stress-free environment.' />
      <meta name='keywords' content='' />
      <link rel="canonical" href="https://www.acmefertility.com/RightEnvironment" />
      <meta name="robots" content="Index, follow " />
    </Helmet>
      <AboutInnerBanner image={image} heading={heading} pageName={pageName} />
      <AboutContent bg={bg} doctor={doctor} title={title} data={data}/>
    </>
  )
}

export default Transition(RightEnvironment) 