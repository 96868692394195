import React from 'react'
import { AboutInnerBanner } from '../../Components/Common/InnerBanner/AboutInnerBanner'
import AboutContent from '../../Components/Common/AboutContent/AboutContent'
import AboutInnBanContent from '../../Components/Common/InnerBanner/AboutInnBanContent'
import AboutInfo from '../../Components/Common/AboutContent/AboutInfo'
import Transition from '../../Components/Transition'
import { Helmet } from 'react-helmet'

const AffordableTreat = () => {
  const {image, heading, pageName} = AboutInnBanContent.AffordableTreat;
  const {bg, doctor, title, data} = AboutInfo.AffordableTreat; 
  return (
    <>
      <Helmet>
        <title>Affordable IVF Treatment at ACME Fertility Center</title>
        <meta name='description' content='Best affordable IVF treatments with personalized care, technology, and support for a successful journey to parenthood.' />
        <meta name='keywords' content='fertility clinic near me, ivf near me, fertility specialist near me ' />
        <link rel="canonical" href="https://www.acmefertility.com/AffordableTreatment" />
        <meta name="robots" content="Index, follow " />
      </Helmet>
      <AboutInnerBanner image={image} heading={heading} pageName={pageName} />
      <AboutContent bg={bg} doctor={doctor} title={title} data={data} />
    </>
  )
}

export default Transition(AffordableTreat) 