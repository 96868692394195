import React from 'react'
import RightHandSide from '../../Common/Services/RightHandSide'
import AboutInnBanContent from '../../Common/InnerBanner/AboutInnBanContent';
import { AboutInnerBanner } from '../../Common/InnerBanner/AboutInnerBanner';
import HeadContent from '../BlogContent/HeadContent';
import HeadDesign from '../BlogContent/HeadDesign';
import Info from '../BlogContent/Info';
import List from '../BlogContent/List';
import InfoContent from '../BlogContent/InfoContent';
import ListContent from '../BlogContent/ListContent';

const Blog7 = () => {
    const {image, heading, pageName} = AboutInnBanContent.BlogDetails;
    const {bg, para, date, blogHeading} = HeadContent.blog_7;

    const {title, p} = InfoContent.Blog_7.one;
    const {title2, p2} = InfoContent.Blog_7.two;
    const {title3, p3} = InfoContent.Blog_7.three;
    const {Ctitle, Cp} = InfoContent.Blog_7.conclusion;

    const {liss} = ListContent.Blog_7.L1;
    const {liss2} = ListContent.Blog_7.L2;
    const {liss3} = ListContent.Blog_7.L3;

  return (
    <>
<AboutInnerBanner image={image} heading={heading} pageName={pageName} />

<div className="blog-area pt-100 pb-70">
<div className="container">
    <div className="row">
        <div className="col-lg-8">
           <HeadDesign bg={bg} para={para} date={date} blogHeading={blogHeading} />
           <Info title={title} p={p} />

            <h4>Many factors contribute to secondary infertility, including:</h4>
            <List liss={liss} />

            <Info title={title2} p={p2} />
            <Info title={title3} p={p3} />

            <h4>Challenges in IVF for Secondary Infertility</h4>
            <List liss={liss2} />

            <h4>Managing Secondary Infertility and IVF </h4>
            <List liss={liss3} />

            <Info title={Ctitle} p={Cp} />

         
        </div>
        <div className="col-lg-4">
            <RightHandSide/>
        </div>
    </div>
</div>
</div>
    </>
  )
}

export default Blog7