import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import SDevide from './Common/SDevide';
import SDevideData from './Common/SDevideData';
import AllDetails from '../Common/Services/AllDetails';
import AllDetailsData from '../Common/Services/AllDetailsData';
import { Helmet } from 'react-helmet';
import icon from '../../Assets/images/icons/logo.png'

const LaserAssistedHatching = () => {
    const {greenHeading, head, video, pOne} = TopContextData.LaserAssistedHatching;
    const {sdHeading, sdImg, SDdata} = SDevideData.LaserAssistedHatching;
    const {AllDData} = AllDetailsData.LaserAssistedHatching;
  return (
    <>
    <Helmet>
      <title>Best IVF Results with Laser Assisted Hatching Procedure</title>
      <meta name='description' content='Best improve IVF success with laser assisted hatching, helping embryos implant for patients with specific fertility challenges.' />
      <meta name='keywords' content='in vitro fertilization, ivf process, ivf procedure, ivf pregnancy, ivf meaning, ivf cost, ivf process step by step, icsi ivf, embryo transfer, ivf cost in mumbai, ivf price, iui procedure' />
      <link rel="canonical" href="https://acmefertility.com/Programme/LaserAssistedHatching" />
      <meta name="robots" content="Index, follow " />
      <link rel="icon" href={icon} />
    </Helmet>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <div class="blog-card test-details-card"><h4>Which patients could benefit from this procedure?</h4></div>
    <SDevide sdHeading={sdHeading} sdImg={sdImg} SDdata={SDdata} />
    <AllDetails AllDData={AllDData} />
    </>
  )
}

export default LaserAssistedHatching