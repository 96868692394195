import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import AllDetails from '../Common/Services/AllDetails';
import AllDetailsData from '../Common/Services/AllDetailsData';
import SDevide from './Common/SDevide';
import SDevideData from './Common/SDevideData';
import { Helmet } from 'react-helmet';
import icon from '../../Assets/images/icons/logo.png'

const IUI = () => {
    const {greenHeading, head, video, pOne} = TopContextData.IUI;
    const {AllDData} = AllDetailsData.IUI.first;
    const {sdHeading, sdImg, SDdata} = SDevideData.IUI.male;
    const {sdHeading1, sdImg1, SDdata1} = SDevideData.IUI.female;
    const {sdHeading2, sdImg2, SDdata2} = SDevideData.IUI.Preparation;
    const {AllDData1} = AllDetailsData.IUI.second;
    const {AllDData2} = AllDetailsData.IUI.third;
    const {AllDData3} = AllDetailsData.IUI.four;


  return ( 
    <>
    <Helmet>
      <title>Best IUI Treatment: First-Line Infertility Solution</title>
      <meta name='description' content='Best IUI treatment for infertility: effective for male and female factors, enhancing pregnancy success rates.' />
      <meta name='keywords' content='intrauterine insemination iui, ovulation induction, male factor infertility, oligoasthenoteratozoospermia, success rates of iui, iui, iui procedure, iui treatment' />
      <link rel="canonical" href="https://www.acmefertility.com/Programme/IUI" />
      <meta name="robots" content="Index, follow " />
      <link rel="icon" href={icon} />

    </Helmet>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <AllDetails AllDData={AllDData} />
    <SDevide sdHeading={sdHeading} sdImg={sdImg} SDdata={SDdata} />
    <SDevide sdHeading={sdHeading1} sdImg={sdImg1} SDdata={SDdata1} />
    <AllDetails AllDData={AllDData1} />
    <SDevide sdHeading={sdHeading2} sdImg={sdImg2} SDdata={SDdata2} />
    <AllDetails AllDData={AllDData2}/>

    <div class="blog-card test-details-card"><h4>Other Techniques</h4></div>
    <AllDetails AllDData={AllDData3}/>

    
    </>
  )
}

export default IUI