import React from 'react'
import { AboutInnerBanner } from '../../Components/Common/InnerBanner/AboutInnerBanner'
import AboutContent from '../../Components/Common/AboutContent/AboutContent'
import AboutInnBanContent from '../../Components/Common/InnerBanner/AboutInnBanContent'
import AboutInfo from '../../Components/Common/AboutContent/AboutInfo'
import Transition from '../../Components/Transition'
import { Helmet } from 'react-helmet'

const PersonalizedCare = () => {
  const {image, heading, pageName} = AboutInnBanContent.personalizedCare;
  const {bg, doctor, title, data} = AboutInfo.personalizedCare;
  return (
    <>
      <Helmet>
        <title>Best Personalized Fertility Care at ACME FERTILITY, Chembur</title>
        <meta name='description' content='At ACME Fertility Chembur, we offer personalized treatments, support, and wellness initiatives to improve your fertility.' />
        <meta name='keywords' content='Acme fertility , Ivf Fertility , IUI , IVF , Best fertility Treatment near me personalized care , ivf , fertility clinic near me ,in vitro fertilization ivf near me , fertility specialist near me ,ivf process , ivf clinic near me ivf center near me ,fertility specialist' />
        <link rel="canonical" href="https://www.acmefertility.com/PersonalizedCare" />
        <meta name="robots" content="Index, follow " />
      </Helmet>
      
    <AboutInnerBanner image={image} heading={heading} pageName={pageName} />
    <AboutContent bg={bg} doctor={doctor} title={title} data={data}/>
    </>
  )
}

export default Transition(PersonalizedCare) 