import React from 'react'
import { Link} from 'react-router-dom'
import Transition from '../../Transition'

const RightHandSide = () => {
  // const navigate = useNavigate();

  // const goToPage = (id) => {
  //   id.classList.add('active');
  //   navigate("/Programme")
  // }
  return (
    <>
    <div className="details-info">
          <h5>Success Rate</h5>
          <ul className="categorie-list">
            <li>
              <i className="bx bxs-copy" />
              <Link>IVF</Link>
              <span>60+</span>
            </li>
            <li>
              <i className="bx bxs-copy" />
              <Link>ICSI</Link>
              <span>72+</span>
            </li>
            <li>
              <i className="bx bxs-copy" />
              <Link>IUI</Link>
              <span>35+</span>
            </li>
            <li>
              <i className="bx bxs-copy" />
              <Link>Egg Donation</Link>
              <span>75+</span>
            </li>
            <li>
              <i className="bx bxs-copy" />
              <Link>Embryo Donation</Link>
              <span>76+</span>
            </li>
            <li>
              <i className="bx bxs-copy" />
              <Link>Surrogacy</Link>
              <span>80+</span>
            </li>
           
          </ul>
        </div>
        <div className="details-info">
          <h5>Programe</h5>
          <div className="tag">
            <Link to="/Programme/IVF">IVF</Link>
            <Link to="/Programme/ICSI">ICSI</Link>
            <Link to="/Programme/PRP">PRP</Link>
            <Link to="/Programme/IUI">IUI</Link>
            <Link to="/Programme/EmbryoTransfer">Embryo Transfer</Link>
            <Link to="/Programme/AssistedHeching">Assisted Heching</Link>
            <Link to="/Programme/PGS">PGS</Link>
            <Link to="/Programme/MACS">MACS</Link>
            <Link to="/Programme/GamateDonars">Gamate Donars</Link>
            <Link to="/Programme/SemenCryopreservation">Semen Cryopreservation</Link>
            <Link to="/Programme/EmbryoCryopreservation">Embryo Cryopreservation</Link>
            <Link to="/Programme/ForeignPatientCare">Foreign Patient Care</Link>
            <Link to="/Programme/SingleEmbryoTransfer">Single Embryo Transfer</Link>
            <Link to="/Programme/LaserAssistedHatching">Laser Assisted Hatching</Link>
            <Link to="/Programme/OocyteFreezing">Oocyte Freezing</Link>
            <Link to="/Programme/PICSI">PISCI</Link>
          </div>
        </div>
    </>
  )
}

export default Transition(RightHandSide) 