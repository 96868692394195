import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import DbListData from '../Common/Services/DbListData';
import DbList from '../Common/Services/DbList';
import AllDetails from '../Common/Services/AllDetails';
import AllDetailsData from '../Common/Services/AllDetailsData';
import { Helmet } from 'react-helmet';
import icon from '../../Assets/images/icons/logo.png'


const PGS = () => {
    const {greenHeading, head, video, pOne} = TopContextData.PGS;
    const {dlHeading, Dldata} = DbListData.PGS.one;
    const {dlHeading2, Dldata2} = DbListData.PGS.two;
    const {AllDData} = AllDetailsData.PGS;

  return (
    <>
    <Helmet>
      <title>PGS Screening for Chromosomal Abnormalities in IVF Embryos</title>
      <meta name='description' content='PGS checks embryos for chromosomal issues to improve IVF success, especially for older women and recurrent miscarriage cases.' />
      <meta name='keywords' content='preimplantation genetic screening, aneuploidy screening, pgs, ivf, Icsi' />
      <link rel="canonical" href="https://acmefertility.com/Programme/PGS" />
      <meta name="robots" content="Index, follow " />
      <link rel="icon" href={icon} />
    </Helmet>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <div class="blog-card test-details-card"><h4>Is PGS for me?</h4></div>
    <DbList dlHeading={dlHeading} Dldata={Dldata} />

    <div class="blog-card test-details-card"><h4>How does PGS work?</h4></div>
    <DbList dlHeading={dlHeading2} Dldata={Dldata2} />

    <AllDetails AllDData={AllDData}/>
    </>
  )
}

export default PGS