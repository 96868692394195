import React from 'react';
import { AboutInnerBanner } from '../../Components/Common/InnerBanner/AboutInnerBanner';
import AboutInnBanContent from '../../Components/Common/InnerBanner/AboutInnBanContent';
import RightHandSide from '../../Components/Common/Services/RightHandSide';
import TopContent from '../../Components/Common/Services/TopContent';
import TopContentTxt from '../../Components/Common/Services/TopContentTxt';
import DbDevideData from '../../Components/Common/Services/DbDevideData';
import DbDevide from '../../Components/Common/Services/DbDevide';
import DbList from '../../Components/Common/Services/DbList';
import DbListData from '../../Components/Common/Services/DbListData';
import Transition from '../../Components/Transition';
import { Helmet } from 'react-helmet';

const FollicularMonitoring = () => {
  const {image, heading, pageName} = AboutInnBanContent.FollicularMonitoring;
  const {banner, head, pOne, pTwo, highligth} = TopContentTxt.FollicularMonitoring;
  const {dbHeading, dbImg, DBdata} = DbDevideData.FollicularMonitoring;
  const {dlHeading, Dldata} = DbListData.FollicularMonitoring;
  

  return (
    <>
      <Helmet>
        <title>Follicular Monitoring for Optimal Fertility Care</title>
        <meta name='description' content='See how to track the development of eggs and the uterine lining with follicular monitoring to increase your chances of becoming pregnant.' />
        <meta name='keywords' content='follicular monitoring, egg maturation, intrauterine insemination iui, in vitro fertilization ivf, ovarian follicles, iui, fertility tracker, in vitro fertilization, iui procedure' />
        <link rel="canonical" href="https://www.acmefertility.com/FollicularMonitoring" />
        <meta name="robots" content="Index, follow " />
      </Helmet>

    <AboutInnerBanner image={image} heading={heading} pageName={pageName} />

    <div className="blog-details-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <TopContent banner={banner} head={head} pOne={pOne} pTwo={pTwo} highligth={highligth} />
            <DbDevide dbHeading={dbHeading} dbImg={dbImg} DBdata={DBdata} />
            <DbList dlHeading={dlHeading} Dldata={Dldata} />
          </div>
          <div className="col-lg-4">
            <RightHandSide />
        </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Transition(FollicularMonitoring) 