import React from 'react'
import { Link } from 'react-router-dom'
import RightHandSide from "../Common/Services/RightHandSide"
import Transition from '../Transition'

const Blog = () => {
  return (
    <>
    <div className="blog-area pt-100 pb-70">
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="row">
                        {data.map((d, i) => (
                            <div key={i} className="col-lg-6 col-md-6 col-sm-6">
                                <div className="blog-card">
                                    <img src={d.BlogImg} className='rounded' alt="image1" />
                                    <Link to={d.careLink} className="care">{d.care}</Link>
                                    <h4>
                                        <Link to={d.blogLink}>{d.BlogHeading}</Link>
                                    </h4>
                                    <ul>
                                        <li className='first'>By <Link to="/Doctor">Admin</Link></li>
                                        <li className='second'>{d.date}</li>
                                    </ul>
                                </div>
                            </div>
                        ))}
                        
                        
                    </div>
                    {/* <div className="result-pagination search-pagination">
                        <nav>
                            <ul className="pagination">
                                <li className="page-item">
                                    <a className="page-link" href="#" aria-label="Previous">
                                        <span aria-hidden="true">
                                            <i className="bx bx-chevron-left"></i>
                                        </span>
                                    </a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link active" href="#">01</a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href="#">02</a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href="#">03</a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href="#" aria-label="Next">
                                        <span aria-hidden="true">
                                            <i className="bx bx-chevron-right"></i>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div> */}
                </div>
                <div className="col-lg-4">
                    <RightHandSide />
                </div>
            </div>
        </div>
    </div>
    
    
    </>
  )
}

export default Transition(Blog) 

const data = [
    {
        BlogImg: require("../../Assets/images/blog/headDesign/1.png"),
        careLink: "/Doctor",
        care: "Hospital",
        blogLink: "/1/BlogDetails",
        BlogHeading: "How to Prepare for Your First IVF Cycle: A Step-by-Step Guide",
        date: "25 Oct, 2024"
    },
    {
        BlogImg: require("../../Assets/images/blog/headDesign/2.png"),
        careLink: "/Doctor",
        care: "Hospital",
        blogLink: "/2/BlogDetails",
        BlogHeading: "The Role of Genetics in IVF: What You Should Know",
        date: "26 Oct, 2024"
    },
    {
        BlogImg: require("../../Assets/images/blog/headDesign/3.png"),
        careLink: "/Doctor",
        care: "Hospital",
        blogLink: "/3/BlogDetails",
        BlogHeading: "IVF and Age: When is the Best Time to Start?",
        date: "28 Oct, 2024"
    },
    {
        BlogImg: require("../../Assets/images/blog/headDesign/4.png"),
        careLink: "/Doctor",
        care: "Hospital",
        blogLink: "/4/BlogDetails",
        BlogHeading: "The Latest Innovations in IVF Technology for 2024",
        date: "28 Oct, 2024"
    },
    {
        BlogImg: require("../../Assets/images/blog/headDesign/5.png"),
        careLink: "/Doctor",
        care: "Hospital",
        blogLink: "/5/BlogDetails",
        BlogHeading: "Importance of Mental Health Support During IVF Treatments",
        date: "30 Oct, 2024"
    },
    {
        BlogImg: require("../../Assets/images/blog/headDesign/6.png"),
        careLink: "/Doctor",
        care: "Hospital",
        blogLink: "/6/BlogDetails",
        BlogHeading: "Egg Freezing vs. IVF: Which is the Better Option for You? ",
        date: "30 Oct, 2024"
    },
    {
        BlogImg: require("../../Assets/images/blog/headDesign/7.png"),
        careLink: "/Doctor",
        care: "Hospital",
        blogLink: "/7/BlogDetails",
        BlogHeading: "Unexpected Challenges in IVF: How to Navigate Secondary Infertility",
        date: "30 Oct, 2024"
    },
    // {
    //     BlogImg: require("../../Assets/images/about/advanceTech.png"),
    //     careLink: "/Doctor",
    //     care: "Hospital",
    //     blogLink: " ",
    //     BlogHeading: "How Lifestyle Changes Can Boost Your IVF Success",
    //     date: "2 Nov, 2024"
    // },
    {
        BlogImg: require("../../Assets/images/blog/headDesign/9.png"),
        careLink: "/Doctor",
        care: "Hospital",
        blogLink: "/9/BlogDetails",
        BlogHeading: "IVF Success Rates & Statistics: Understanding Your Chances ",
        date: "02 Nov, 2024"
    },
    {
        BlogImg: require("../../Assets/images/blog/headDesign/10.png"),
        careLink: "/Doctor",
        care: "Hospital",
        blogLink: "/10/BlogDetails",
        BlogHeading: "PCOS and Fertility Explained: Understanding the Connection",
        date: "04 Nov, 2024"
    },
    {
        BlogImg: require("../../Assets/images/blog/headDesign/11.png"),
        careLink: "/Doctor",
        care: "Hospital",
        blogLink: "/11/BlogDetails",
        BlogHeading: "What is a Frozen Embryo Transfer (FET)?",
        date: "04 Nov, 2024"
    },


]
    

