import { motion } from "framer-motion";

const Transition = (OgComponent) =>{
    return() =>(
        <>
            <OgComponent/>
            <motion.div 
            className="Slide-In" 
            initial= {{scaleY: 0}}
            animate= {{scaleY: 0}}
            exit={{scaleY: 1}}
            transition={{ duration: 0.8, ease: [0.22, 1, 0.36, 2] }}
            > 
            </motion.div>

            <motion.div
             className="Slide-out" 
             initial= {{scaleY: 1}}
             animate= {{scaleY: 0}}
             exit={{scaleY: 0}}
             transition={{ duration: 0.8, ease: [0.22, 1, 0.36, 2] }}
            />

        </>
    )
}

export default Transition