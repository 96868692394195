
const AboutInnBanContent = {
    intruduction:{
        image: require('../../../Assets/images/about/1`.jpg'),
        heading: "Introduction",
        pageName: "Introduction"
    },
    qualityExpertise: {
        image: require('../../../Assets/images/about/QualityExpertise.png'),
        heading: "Quality & Expertise",
        pageName: "Quality & Expertise"
    },
    personalizedCare: {
        image: require('../../../Assets/images/about/PersonalizedCare.png'),
        heading: "Personalized Care",
        pageName: "Personalized Care"
    },
    advancedTechnology: {
        image: require('../../../Assets/images/about/AdvancedTechnology.png'),
        heading: "Advanced Technology",
        pageName: "Advanced Technology"
    },
    AffordableTreat: {
        image: require('../../../Assets/images/about/AffordableTreatment.png'),
        heading: "Affordable Treatment",
        pageName: "Affordable Treatment"
    },
    RightEnvironment: {
        image: require('../../../Assets/images/about/RightEnvironment.png'),
        heading: "Right Environment",
        pageName: "Right Environment"
    },

    // Appointment Banner
    Appointment: {
        image: require('../../../Assets/images/banner/appointment.png'),
        heading: "Appointment",
        pageName: "Appointment"
    },

    // Contact Banner
    Contact: {
        image: require('../../../Assets/images/popular/services/serviceMenu/search-img.jpg'),
        heading: "Contact Us",
        pageName: "Contact"
    },

    // Doctors 
    Doctor: {
        image: require('../../../Assets/images/banner/doctor.jpg'),
        heading: "Doctor Profile",
        pageName: "Doctor Profile"
    },

    // FertilityCounselling
    FertilityCounselling:{
        image: require('../../../Assets/images/programe/FertilityCounselling.png'),
        heading: "Fertility Counselling",
        pageName: "Fertility Counselling"
    },

    //LaparoscopyHysteroscopy
    LaparoscopyHysteroscopy:{
        image: require('../../../Assets/images/programe/LaparoscopyHysteroscopy.png'),
        heading: "Laparoscopy Hysteroscopy",
        pageName: "Laparoscopy Hysteroscopy"
    },
    
    //WomenHealthCare
    WomenHealthCare:{
        image: require('../../../Assets/images/programe/WomenHealthCare.png'),
        heading: "Women Health Care",
        pageName: "Women Health Care"
    },

    // HormonalAssays
    HormonalAssays:{
        image: require('../../../Assets/images/programe/HormonalAssays.png'),
        heading: "Hormonal Assays",
        pageName: "Hormonal Assays"
    },

    FollicularMonitoring:{
        image: require('../../../Assets/images/programe/FollicularMonitoring.png'),
        heading: "Follicular Monitoring",
        pageName: "Follicular Monitoring"
    },

    MaleInfertility:{
        image: require('../../../Assets/images/programe/MaleInfertility.png'),
        heading: "Male Infertility",
        pageName: "Male Infertility"
    },

    ObstetricCare:{
        image: require('../../../Assets/images/programe/ObstetricCare.png'),
        heading: "Obstetric Care",
        pageName: "Obstetric Care"
    },

    UrinaryIncontinenceManagement:{
        image: require('../../../Assets/images/programe/UrinaryIncontinenceManagement.png'),
        heading: "Urinary Incontinence Management",
        pageName: "Urinary Incontinence Management"
    },
    
    FAQ: {
        image: require('../../../Assets/images/banner/appointment-img.jpg'),
        heading: "FAQ",
        pageName: "FAQ"
    },

    Programe: {
        image: require('../../../Assets/images/programe/program.png'),
        heading: "Programme",
        pageName: "Programme"
    },

    Blog: {
        image: require('../../../Assets/images/banner/blog.png'),
        heading: "Blogs",
        pageName: "Blogs"
    },

    BlogDetails: {
        image: require('../../../Assets/images/banner/blogDetails.png'),
        heading: "Blog Details",
        pageName: "Blog Details"
    }
};

export default AboutInnBanContent;
