import React from 'react';
import { AboutInnerBanner } from '../../Components/Common/InnerBanner/AboutInnerBanner';
import AboutInnBanContent from '../../Components/Common/InnerBanner/AboutInnBanContent';
import RightHandSide from '../../Components/Common/Services/RightHandSide';
import TopContent from '../../Components/Common/Services/TopContent';
import TopContentTxt from '../../Components/Common/Services/TopContentTxt';
import DbDevideData from '../../Components/Common/Services/DbDevideData';
import DbDevide from '../../Components/Common/Services/DbDevide';
import AllDetailsData from '../../Components/Common/Services/AllDetailsData';
import AllDetails from '../../Components/Common/Services/AllDetails';
import Transition from '../../Components/Transition';
import { Helmet } from 'react-helmet';

const ObstetricCare = () => {
  const {image, heading, pageName} = AboutInnBanContent.ObstetricCare;
  const {banner, head, pOne, pTwo, highligth} = TopContentTxt.ObstetricCare;
  const {dbHeading1, dbImg1, DBdata1} = DbDevideData.ObstetricCare.first;
  const {AllDData} = AllDetailsData.ObstetricCare.up;
  const {dbHeading2, dbImg2, DBdata2} = DbDevideData.ObstetricCare.Two;
  const {AllDData2} = AllDetailsData.ObstetricCare.down;
  return (
    <>
    <Helmet>
      <title>Best Prenatal Care in Mumbai | Expert Obstetric Services</title>
      <meta name='description' content='Best obstetric care with regular check-ups for healthy pregnancy. Comprehensive prenatal services at Acme Fertility.' />
      <meta name='keywords' content=' ivf, icsi, egg donation, iui, Surrogacy, Fertility, Ivf fertility, pregnancy, healthy lifestyles, mother, miscarriages, ' />
      <link rel="canonical" href="https://www.acmefertility.com/ObstetricCare" />
      <meta name="robots" content="Index, follow " />
    </Helmet>
    <AboutInnerBanner image={image} heading={heading} pageName={pageName} />
    
    <div className="blog-details-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <TopContent banner={banner} head={head} pOne={pOne} pTwo={pTwo} highligth={highligth} />

            <DbDevide dbHeading={dbHeading1} dbImg={dbImg1} DBdata={DBdata1} />

            <AllDetails AllDData={AllDData} />

            <DbDevide dbHeading={dbHeading2} dbImg={dbImg2} DBdata={DBdata2} />

            <AllDetails AllDData={AllDData2} />
          </div>
          <div className="col-lg-4">
            <RightHandSide />
        </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Transition(ObstetricCare) 