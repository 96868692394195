import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import AllDetailsData from '../Common/Services/AllDetailsData';
import AllDetails from '../Common/Services/AllDetails';
import { Helmet } from 'react-helmet';
import icon from '../../Assets/images/icons/logo.png'


const SemenCrypto = () => {
    const {greenHeading, head, video, pOne} = TopContextData.SemenCrypto;
    const {AllDData} = AllDetailsData.SemenCrypto;

  return (
    <>
    <Helmet>
      <title>Best Sperm Cryopreservation Services at Acme Fertility</title>
      <meta name='description' content='Best sperm freezing process for IVF or IUI, ensuring safe, long-term storage with high success rates at Acme Fertility.' />
      <meta name='keywords' content='in vitro fertilization, ivf process, ivf procedure, ivf pregnancy, ivf process step by step, in vitro fertilization cost, icsi ivf, ivf cost in mumbai, ivf procedure step by step, intrauterine insemination, iui procedure, iui treatment, ivf injections, ivf medications' />
      <link rel="canonical" href="https://acmefertility.com/Programme/SemenCryopreservation" />
      <meta name="robots" content="Index, follow " />
      <link rel="icon" href={icon} />
    </Helmet>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <AllDetails AllDData={AllDData} />
    
    </>
  )
}

export default SemenCrypto