import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import DbList from '../Common/Services/DbList'
import DbListData from '../Common/Services/DbListData'

const ICSI = () => {
    const {greenHeading1, head1, video1, pOne1, pTwo1} = TopContextData.ICSI
    const {dlHeading2, Dldata2} = DbListData.IVF_ICSI.two;
  return (
    <>
    <TopContext greenHeading={greenHeading1} head={head1} video={video1} pOne={pOne1} pTwo={pTwo1} />
    <DbList dlHeading={dlHeading2} Dldata={Dldata2} />
    </>
  )
}

export default ICSI