import React from 'react'
import { AboutInnerBanner } from '../Components/Common/InnerBanner/AboutInnerBanner'
import AboutInnBanContent from '../Components/Common/InnerBanner/AboutInnBanContent';
import Blog from '../Components/Blogs/Blog';
import Transition from '../Components/Transition';

const BlogPage = () => {
    const {image, heading, pageName} = AboutInnBanContent.Blog;
  return (
    <>
    <AboutInnerBanner image={image} heading={heading} pageName={pageName} />
    <Blog/>
    </>
  )
}

export default Transition(BlogPage) 