import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import DbList from '../Common/Services/DbList';
import DbListData from '../Common/Services/DbListData';
import { Helmet } from 'react-helmet';
import icon from '../../Assets/images/icons/logo.png'


const PICSI = () => {
    const {greenHeading, head, video, pOne} = TopContextData.PICSI;
    const {dlHeading, Dldata} = DbListData.PICSI;
  return (
    <>
    <Helmet>
      <title>Best PICSI Technique for IVF: Improved Pregnancy Rates</title>
      <meta name='description' content='Best PICSI technique enhances IVF success by selecting mature, healthy sperm.' />
      <meta name='keywords' content='' />
      <link rel="canonical" href="https://acmefertility.com/Programme/PICSI" />
      <meta name="robots" content="Index, follow " />
      <link rel="icon" href={icon} />
    </Helmet>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <DbList dlHeading={dlHeading} Dldata={Dldata} />
    </>
  )
}

export default PICSI