import React from 'react'
import AboutInnBanContent from '../Components/Common/InnerBanner/AboutInnBanContent'
import { AboutInnerBanner } from '../Components/Common/InnerBanner/AboutInnerBanner';
import FAQContent from '../Components/FAQ/FAQContent';
import Transition from '../Components/Transition';
import { Helmet } from 'react-helmet';
// import Hero from '../Components/Homepage/Hero';

const FAQ = () => {
    const {image, heading, pageName} = AboutInnBanContent.FAQ;

  return (
    <>

    <Helmet>
      <title>Best Fertility FAQ | Acme Fertility Hospital Treatments & Services</title>
      <meta name='description' content='Find expert answers on fertility, IVF, IUI, and more at Acme Fertility Hospital. Empower your journey to parenthood.' />
      <meta name='keywords' content='' />
      <link rel="canonical" href="https://www.acmefertility.com/FAQ" />
      <meta name="robots" content="Index, follow " />
    </Helmet>

    <AboutInnerBanner image={image} heading={heading} pageName={pageName} />
    
    <FAQContent />

    {/* <Hero/> */}

    </>
  )
}

export default Transition(FAQ) 