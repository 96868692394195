import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import AllDetails from '../Common/Services/AllDetails';
import AllDetailsData from '../Common/Services/AllDetailsData';
import { Helmet } from 'react-helmet';
import icon from '../../Assets/images/icons/logo.png'

const SET = () => {
    const {greenHeading, head, video, pOne} = TopContextData.SET;
    const {AllDData} = AllDetailsData.SET;

  return (
    <>
     <Helmet>
      <title>Best IVF Success: Elective Single Embryo Transfer (eSET)</title>
      <meta name='description' content='Best reduce IVF risks with elective single embryo transfer (eSET) for healthier pregnancies and better outcomes.' />
      <meta name='keywords' content='embryo, embryo transfer, embryo adoption, donor embryo, frozen embryo transfer' />
      <link rel="canonical" href="https://acmefertility.com/Programme/SingleEmbryoTransfer" />
      <meta name="robots" content="Index, follow " />
      <link rel="icon" href={icon} />
    </Helmet>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <AllDetails AllDData={AllDData} />
    </>
  )
}

export default SET