import React from 'react'
import TopContextData from './Common/TopContextData'
import TopContext from './Common/TopContext';
import AllDetails from '../Common/Services/AllDetails';
import AllDetailsData from '../Common/Services/AllDetailsData';
import { Helmet } from 'react-helmet';
import icon from '../../Assets/images/icons/logo.png'

const PRP = () => { 

    const {greenHeading, head, video, pOne} = TopContextData.PRP;
    const {AllDData} = AllDetailsData.PRP;


  return (
    <>
    <Helmet>
      <title>PRP Treatment for Thin Endometrium and Asherman Syndrome</title>
      <meta name='description' content='PRP improves endometrial thickness in women with thin endometrium/Asherman’s, achieving 8 pregnancies out of 10 cases.' />
      <meta name='keywords' content='Platelet-Rich Plasma, thin endometrium treatment, magnetic activated cell sorting macs, frozen embryo transfer, magnetic activated cell sorting' />
      <link rel="canonical" href="https://www.acmefertility.com/Programme/PRP" />
      <meta name="robots" content="Index, follow " />
      <link rel="icon" href={icon} />
    </Helmet>
    
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <AllDetails AllDData={AllDData} />
    </>
  )
}

export default PRP