import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import DbList from '../Common/Services/DbList';
import DbListData from '../Common/Services/DbListData';
import { Helmet } from 'react-helmet';
import icon from '../../Assets/images/icons/logo.png'

const MACS = () => {
    const {greenHeading, head, video, pOne} = TopContextData.MACS;
    const {dlHeading, Dldata} = DbListData.MACS;
  return (
    <>
    <Helmet>
      <title>Improving Sperm Quality to Ensure Successful IVF</title>
      <meta name='description' content='Best method for selecting functional sperm, improving pregnancy rates with Magnetic Activated Cell Sorting (MACS) in ART' />
      <meta name='keywords' content='' />
      <link rel="canonical" href="https://acmefertility.com/Programme/MACS" />
      <meta name="robots" content="Index, follow " />
      <link rel="icon" href={icon} />
    </Helmet>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <DbList dlHeading={dlHeading} Dldata={Dldata}/>
    </>
  )
}

export default MACS