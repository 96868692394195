import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import DbListData from '../Common/Services/DbListData';
import DbList from '../Common/Services/DbList';
import AllDetailsData from '../Common/Services/AllDetailsData';
import AllDetails from '../Common/Services/AllDetails';
import { Helmet } from 'react-helmet';
import icon from '../../Assets/images/icons/logo.png'


const GamateDonars = () => {
    const {greenHeading, head, video, pOne} = TopContextData.GamateDonars;
    const {dlHeading, Dldata} = DbListData.GamateDonars.first;
    const {AllDData} = AllDetailsData.GamateDonars;
  return ( 
    <>
     <Helmet>
      <title>Best Egg Donation Program at Acme Fertility</title>
      <meta name='description' content='Best egg donation program for women with low ovarian reserve, IVF failures, or chromosomal anomalies at Acme Fertility.' />
      <meta name='keywords' content='egg donation program, oocyte donation, low ovarian reserve, donor egg ivf' />
      <link rel="canonical" href="https://acmefertility.com/Programme/GamateDonars" />
      <meta name="robots" content="Index, follow " />
      <link rel="icon" href={icon} />
    </Helmet>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <DbList dlHeading={dlHeading} Dldata={Dldata} />
    <AllDetails AllDData={AllDData} />
    </>
  )
}

export default GamateDonars