import React from 'react'
import { AboutInnerBanner } from '../Components/Common/InnerBanner/AboutInnerBanner'
import AboutInnBanContent from '../Components/Common/InnerBanner/AboutInnBanContent'
import DoctorProfile from '../Components/DoctorProfile/DoctorProfile';
import Transition from '../Components/Transition';
import { Helmet } from 'react-helmet';

const Doctor = () => {
    const {image, heading, pageName} = AboutInnBanContent.Doctor;
  return (
    <>
    <Helmet>
      <title>Best Fertility Specialist Dr. Neelam Bhise | 20+ Years Experience</title>
      <meta name='description' content='Dr. Neelam Bhise, a renowned fertility specialist, offers advanced IVF treatments with a 90% success rate in complex cases. Located in Mumbai.' />
      <meta name='keywords' content='' />
      <link rel="canonical" href="https://www.acmefertility.com/Doctor" />
      <meta name="robots" content="Index, follow " />
    </Helmet>
    <AboutInnerBanner image={image} heading={heading} pageName={pageName} />
    <DoctorProfile />
    </>
  )
}

export default Transition(Doctor) 