import React from 'react'
import { AboutInnerBanner } from '../../Components/Common/InnerBanner/AboutInnerBanner';
import AboutInnBanContent from '../../Components/Common/InnerBanner/AboutInnBanContent';
import RightHandSide from '../../Components/Common/Services/RightHandSide';
import TopContent from '../../Components/Common/Services/TopContent';
import TopContentTxt from '../../Components/Common/Services/TopContentTxt';
import DbListData from '../../Components/Common/Services/DbListData';
import DbList from '../../Components/Common/Services/DbList';
import AllDetailsData from '../../Components/Common/Services/AllDetailsData';
import AllDetails from '../../Components/Common/Services/AllDetails';
import Transition from '../../Components/Transition';
import { Helmet } from 'react-helmet';

const UrinaryIncontinenceManagement = () => {
  const {image, heading, pageName} = AboutInnBanContent.UrinaryIncontinenceManagement;
  const {banner, head, pOne, pTwo, highligth} = TopContentTxt.UrinaryIncontinenceManagement;
  const {dlHeading1, Dldata1} = DbListData.UrinaryIncontinenceManagement.one;
  const {AllDData} = AllDetailsData.UrinaryIncontinenceManagement.first;
  const {dlHeading2, Dldata2} = DbListData.UrinaryIncontinenceManagement.two;
  const {AllDData1} = AllDetailsData.UrinaryIncontinenceManagement.second;
  const {dlHeading3, Dldata3} = DbListData.UrinaryIncontinenceManagement.three;
  return (
    <>
    <Helmet>
      <title>Acme Fertility Clinic Offers the Best Treatments for Urinary Incontinence</title>
      <meta name='description' content='Best treatment for urinary incontinence with advanced diagnosis and surgical options like TVT, TOT slings, and bladder repositioning.' />
      <meta name='keywords' content='involuntary urination, leakage of urine, quality of life. kegel exercises, kegel exercises for women' />
      <link rel="canonical" href="https://www.acmefertility.com/UrinaryIncontinenceManagement" />
      <meta name="robots" content="Index, follow " />
    </Helmet>
    <AboutInnerBanner image={image} heading={heading} pageName={pageName} />
    
    <div className="blog-details-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <TopContent banner={banner} head={head} pOne={pOne} pTwo={pTwo} highligth={highligth} />
            <DbList dlHeading={dlHeading1} Dldata={Dldata1} />
            <AllDetails AllDData={AllDData} />
            <DbList dlHeading={dlHeading2} Dldata={Dldata2} />
            <div className='blog-card test-details-card'>
              <h4>Treatment - Exercises & Surgical correction</h4>
            </div>
            <AllDetails AllDData={AllDData1} />
            <DbList dlHeading={dlHeading3} Dldata={Dldata3} />
          </div>
          <div className="col-lg-4">
            <RightHandSide />
        </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Transition(UrinaryIncontinenceManagement) 