import React from 'react'
import { AboutInnerBanner } from '../../Components/Common/InnerBanner/AboutInnerBanner';
import AboutInnBanContent from '../../Components/Common/InnerBanner/AboutInnBanContent';
import RightHandSide from '../../Components/Common/Services/RightHandSide';
import TopContent from '../../Components/Common/Services/TopContent';
import TopContentTxt from '../../Components/Common/Services/TopContentTxt';
import DbDevideData from '../../Components/Common/Services/DbDevideData';
import DbDevide from '../../Components/Common/Services/DbDevide';
import DbList from '../../Components/Common/Services/DbList';
import DbListData from '../../Components/Common/Services/DbListData';
import Transition from '../../Components/Transition';
import { Helmet } from 'react-helmet';

const HormonalAssays = () => {
  const {image, heading, pageName} = AboutInnBanContent.HormonalAssays;
  const {banner, head, pOne, pTwo, highligth} = TopContentTxt.HormonalAssays;
  const {dbHeading, dbImg, DBdata} = DbDevideData.HormonalAssays;
  const {dlHeading, Dldata} = DbListData.HormonalAssays;

  return (
    <>
    <Helmet>
      <title>Best Infertility Evaluation by Top Specialists in Mumbai</title>
      <meta name='description' content='Best infertility evaluation includes thorough medical history and personalized testing for effective fertility treatment.' />
      <meta name='keywords' content='infertility evaluation, medical history, chemical exposure, birth control, gynecologic history, birth control pills, mirena iud, contraceptive pill, birth control pills online, birth control options' />
      <link rel="canonical" href="https://www.acmefertility.com/HormonalAssays" />
      <meta name="robots" content="Index, follow " />
    </Helmet>

    <AboutInnerBanner image={image} heading={heading} pageName={pageName} />
    
    <div className="blog-details-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <TopContent banner={banner} head={head} pOne={pOne} pTwo={pTwo} highligth={highligth} />
            <DbDevide dbHeading={dbHeading} dbImg={dbImg} DBdata={DBdata} />
            <DbList dlHeading={dlHeading} Dldata={Dldata} />
          </div>
          <div className="col-lg-4">
            <RightHandSide />
        </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Transition(HormonalAssays) 