import React from "react";
import { Link } from "react-router-dom";

const HeadDesign = ({bg, para, date, blogHeading}) => {
  return (
    <>
      <div className="test-details-card blog-head">
        <img src={bg} className="rounded mb-3" alt="bg" />
        <ul className="byAdmin">
          <li className="first">
            By <Link to="/Doctor">Admin</Link>
          </li>
          <li className="second">{date}</li>
        </ul>
        <div className="test-details-content">
          <div className="details-div d-flex">
            <h4>{blogHeading}</h4>
          </div>
          {para.map((p) => (
            <p className="para">{p.details}</p>
          ))}
        </div>
      </div>
    </>
  );
};

export default HeadDesign;
