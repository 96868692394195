import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import logo from '../../../Assets/images/icons/logo.png'

export const AboutInnerBanner = ({image, heading, pageName}) => {
  return (
    <>
    <Helmet>
    <link rel='icon' href={logo} />
    </Helmet>
    <div class="inner-banner-area" style={{backgroundImage: `url(${image})`}}>
        <div class="container">
            <h2>{heading}</h2>
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>{pageName}</li>
            </ul>   
        </div>
    </div>
    </>
  )
}
