import React from 'react';
import AboutInnBanContent from '../../Common/InnerBanner/AboutInnBanContent';
import { AboutInnerBanner } from '../../Common/InnerBanner/AboutInnerBanner';
import RightHandSide from '../../Common/Services/RightHandSide';
import HeadDesign from '../BlogContent/HeadDesign';
import Transition from '../../Transition';
import HeadContent from '../BlogContent/HeadContent';
import Info from '../BlogContent/Info';
import InfoContent from '../BlogContent/InfoContent';
import List from '../BlogContent/List';
import ListContent from '../BlogContent/ListContent';

const Blog1 = () => {
    const {image, heading, pageName} = AboutInnBanContent.BlogDetails;
    const {bg, para, date, blogHeading} = HeadContent.blog_1;
    const {title, p} = InfoContent.Blog_1.step_1;
    const {liss} = ListContent.Blog_1.step_1;
    const {title2, p2} = InfoContent.Blog_1.step_2;
    const {liss2} = ListContent.Blog_1.step_2;

    const {title3, p3} = InfoContent.Blog_1.step_3;
    const {liss3} = ListContent.Blog_1.step_3;

    const {title4, p4} = InfoContent.Blog_1.step_4;
    const {liss4} = ListContent.Blog_1.step_4;
    const {title5, p5} = InfoContent.Blog_1.step_5;
    const {liss5} = ListContent.Blog_1.step_5;
    const {title6, p6} = InfoContent.Blog_1.step_6;
    const {liss6} = ListContent.Blog_1.step_6;
    const {title7, p7} = InfoContent.Blog_1.step_7;
    const {liss7} = ListContent.Blog_1.step_7;
    const {title8, p8} = InfoContent.Blog_1.step_8;
    const {liss8} = ListContent.Blog_1.step_8;
    const {title9, p9} = InfoContent.Blog_1.conclusion;


  return (
    <>
        <AboutInnerBanner image={image} heading={heading} pageName={pageName} />

        <div className="blog-area pt-100 pb-70">
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <HeadDesign bg={bg} para={para} date={date} blogHeading={blogHeading} />
                    <Info title={title} p={p} />
                    <List liss={liss} />

                    <Info title={title2} p={p2} />
                    <List liss={liss2} />

                    <Info title={title3} p={p3} />
                    <List liss={liss3} />

                    <Info title={title4} p={p4} />
                    <List liss={liss4} />
                    
                    <Info title={title5} p={p5} />
                    <List liss={liss5} />

                    <Info title={title6} p={p6} />
                    <List liss={liss6} />

                    <Info title={title7} p={p7} />
                    <List liss={liss7} />

                    <Info title={title8} p={p8} />
                    <List liss={liss8} />

                    <Info title={title9} p={p9} />

                </div>
                <div className="col-lg-4">
                    <RightHandSide/>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Transition(Blog1) 