import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import AllDetailsData from '../Common/Services/AllDetailsData';
import AllDetails from '../Common/Services/AllDetails';
import { Helmet } from 'react-helmet';
import icon from '../../Assets/images/icons/logo.png'


const EmbryoTransfer = () => {
    const {greenHeading, head, video, pOne} = TopContextData.EmbryoTransfer;
    const {AllDData} = AllDetailsData.EmbryoTransfer;

  return (
    <>
    <Helmet>
      <title>Best IVF Success with Advanced Tech at ACME Fertility Clinic</title>
      <meta name='description' content='Best IVF care with ultrasonography, air purification, ICSI, and CO2 incubators for higher success rates at ACME Fertility Clinic.' />
      <meta name='keywords' content='fertility clinic, embryo culture, acme fertility, fertility clinic near me, Ivf fertility clinic ' />
      <link rel="canonical" href="https://acmefertility.com/Programme/EmbryoTransfer" />
      <meta name="robots" content="Index, follow " />
      <link rel="icon" href={icon} />
    </Helmet>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <AllDetails AllDData={AllDData} />
    </>
  )
}

export default EmbryoTransfer;