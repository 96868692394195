import { Link } from "react-router-dom";

const ListContent = {
  Blog_1: {
    step_1: {
      liss: [
        {
          bold: "Tip : ",
          item: "Ask your doctor for trusted resources or read credible online articles. Keep a notebook for questions and notes to help during consultations.",
        },
      ],
    },
    step_2: {
      liss2: [
        {
          bold: "Tip : ",
          item: "Consider joining a class at your fertility clinic where they teach proper injection techniques. Some clinics even offer instructional videos.",
        },
      ],
    },
    step_3: {
      liss3: [
        {
          bold: "Diet: ",
          item: "Eat a nutrient-rich diet full of fruits, vegetables, whole grains, and lean proteins. Foods rich in antioxidants and omega-3s are beneficial for reproductive health.",
        },
        {
          bold: "Exercise: ",
          item: "Engage in moderate, low-impact exercises like walking or swimming. Avoid intense workouts, which can negatively affect hormone levels.",
        },
        {
          bold: "Supplements: ",
          item: " Your fertility specialist may recommend certain vitamins, such as folic acid, vitamin D, and CoQ10, to enhance fertility.",
        },
        {
          bold: "Tip: ",
          item: "Maintain a balanced lifestyle without over-stressing your body. This can improve your body's response to fertility treatments.",
        },
      ],
    },
    step_4: {
      liss4: [
        {
          bold: "Support System: ",
          item: "Surround yourself with supportive friends, family, or a partner who can offer encouragement. Consider joining an IVF support group to connect with others on the same journey.",
        },
        {
          bold: "Stress Management: ",
          item: "Practice stress-reduction techniques such as mindfulness, meditation, or yoga. Many fertility centers also offer counseling or therapy services to help manage stress or anxiety related to the process.",
        },
        {
          bold: "Tip: ",
          item: "Prioritize self-care routines that help you relax and recharge, whether it's reading, taking baths, or spending time with loved ones.",
        },
      ],
    },
    step_5: {
      liss5: [
        {
          bold: "Initial Consultation: ",
          item: "Ensure that you and your partner (if applicable) attend an initial consultation where the fertility specialist will review your medical history and run tests like blood work, ultrasounds, or sperm analysis.",
        },
        {
          bold: "Screenings and Testing: ",
          item: "Before IVF, both partners may need screenings for infectious diseases, hormone levels, and general reproductive health. These tests ensure that you're ready to proceed with the IVF cycle safely.",
        },
        {
          bold: "Tip: ",
          item: "Keep a folder or digital file to store all your test results, doctor's recommendations, and appointment schedules.",
        },
      ],
    },
    step_6: {
      liss6: [
        {
          bold: "Cost Breakdown: ",
          item: (
            <>
              <Link to="/Programme/IVF" className="blogLink">
                IVF
              </Link>
              typically includes expenses for medications, monitoring
              appointments, and procedures like egg retrieval and embryo
              transfer. Ask for a detailed cost estimate from your fertility
              clinic.
            </>
          ),
        },
        {
          bold: "Insurance Coverage: ",
          item: "Investigate whether your health insurance covers any part of the IVF treatment, including medications or diagnostic tests.",
        },
        {
          bold: "Financing Options: ",
          item: "Some clinics offer financing plans, grants, or discounts. Explore these options if you need assistance in covering costs.",
        },
        {
          bold: "Tip: ",
          item: "Set a realistic budget and plan for extra expenses, as additional treatments like genetic testing or embryo freezing might be recommended during the process.",
        },
      ],
    },
    step_7: {
      liss7: [
        {
          bold: "Work Schedule: ",
          item: "Plan for time off from work, especially during critical stages like egg retrieval and embryo transfer. Some people need a few days to recover physically and emotionally after these procedures.",
        },
        {
          bold: "Home Support: ",
          item: "Arrange for extra help at home, whether that's hiring a babysitter, asking for help with household chores, or simply having someone available for emotional support.",
        },
        {
          bold: "Tip: ",
          item: "Speak openly with your employer (if comfortable) to see if flexible work hours or remote options are available during your IVF treatment cycle.",
        },
      ],
    },
    step_8: {
      liss8: [
        {
          bold: "Realistic Expectations: ",
          item: "IVF can be unpredictable, and it's important to manage expectations. Success rates vary based on factors such as age, health conditions, and fertility history.",
        },
        {
          bold: "Resilience: ",
          item: "Try to stay hopeful and resilient. Each step in the process brings you closer to your goal, even if it doesn't happen as quickly as you hope.",
        },
        {
          bold: "Tip: ",
          item: "Focus on progress and celebrate small milestones along the way.",
        },
      ],
    },
  },

  Blog_2: {
    L1: {
      liss: [
        {
          bold: "PGT-A (Preimplantation Genetic Testing for Aneuploidy): ",
          item: "This test screens embryos for chromosomal abnormalities, which can lead to conditions like Down syndrome or increase the risk of miscarriage. By identifying chromosomally normal embryos, PGT-A enhances the chances of a successful pregnancy.",
        },
        {
          bold: "PGT-M (Preimplantation Genetic Testing for Monogenic Disorders): ",
          item: "This testing is used to identify specific genetic conditions that can be passed from parent to child, such as cystic fibrosis or sickle cell disease. PGT-M helps prospective parents understand the genetic risks associated with their embryos.",
        },
        {
          bold: "Tip: ",
          item: "Discuss with your fertility specialist whether PGT is appropriate for your situation. Understanding your genetic risks can lead to informed decisions regarding embryo selection.",
        },
      ],
    },
    L2: {
      liss2: [
        {
          bold: "Risk Assessment: ",
          item: "The counselor will evaluate your family history and any previous genetic testing results to provide a personalized risk assessment for inherited conditions.",
        },
        {
          bold: "Informed Decision-Making: ",
          item: "With a clearer understanding of genetic risks, couples can make informed decisions about whether to proceed with IVF, which embryos to transfer, or whether to consider options such as donor gametes or adoption.",
        },
        {
          bold: "Support and Resources: ",
          item: "Genetic counselors also offer emotional support and can connect you with additional resources, such as support groups for families facing genetic challenges.",
        },
      ],
    },

    L3: {
      liss3: [
        {
          bold: "Embryo Quality: ",
          item: "The genetic health of an embryo directly influences its quality. Chromosomally normal embryos have a higher likelihood of implantation and developing into a healthy pregnancy. PGT-A can help identify high-quality embryos for transfer.",
        },
        {
          bold: "",
          item: (
            <>
            Age Factors: The age of the egg provider is a significant factor in <Link to="/Programme/IVF" className="blogLink">IVF success</Link>. Women in their 20s and early 30s tend to produce higher-quality eggs, while women over 35 may experience a decline in egg quality and quantity. Understanding these age-related factors is essential for couples considering IVF.
            </>
          ),
        },
        {
          bold: "Sperm Quality: ",
          item: "Just as egg quality is critical, sperm quality also impacts embryo development. Genetic testing of sperm can identify potential issues, such as chromosomal abnormalities or mutations, which could affect the health of the resulting embryos.",
        },
        {
            bold: "Tip: ",
            item: "Discuss the potential impact of genetics on your IVF success rates with your fertility specialist. Understanding these factors can help set realistic expectations for your treatment journey.",
          },
      ],
    },
    L4: {
        liss4: [
            {
                bold: "Chromosomal Abnormalities: ",
                item: "Conditions such as Turner syndrome or Klinefelter syndrome are caused by chromosomal abnormalities and can lead to fertility issues. Couples with a known history of these conditions may benefit from genetic testing to identify risks."
            },
            {
                bold: "Single-Gene Disorders: ",
                item: "Couples with a family history of single-gene disorders, such as Huntington's disease, can use PGT-M to ensure that embryos do not carry the harmful gene mutations."
            },
            {
                bold: "Carrier Screening: ",
                item: "Before undergoing IVF, prospective parents may opt for carrier screening to determine if they are carriers of specific genetic disorders. This information can influence decisions about embryo selection or donor gametes."
            },
            {
                bold: "Tip: ",
                item: (
                    <>
                    Consult with a genetic counselor or fertility specialist to understand the implications of specific genetic conditions for your <Link to="/Programme/IVF" className="blogLink">IVF journey</Link>.
                    </>
                )
            },
            
        ]
    },

    L5: {
        liss5: [
            {
                bold: "Informed Consent: ",
                item: "It's essential for patients to fully understand the potential implications of genetic testing and the decisions that may arise from the results. Informed consent is crucial in navigating these ethical dilemmas."
            },
            {
                bold: "Regulatory Oversight: ",
                item: "Genetic testing practices are subject to regulatory oversight to ensure patient safety and ethical considerations are addressed. Understanding the regulatory landscape can help patients navigate their choices."
            },
            {
                bold: "Tip: ",
                item: "Engage in open conversations with your fertility clinic regarding ethical considerations surrounding genetic testing. Ensure that your values align with the practices of your healthcare team."
            },
        ]
    },

  },

  Blog_3: {
    L1: {
      liss: [
        {
          bold: "Ovarian Reserve: ",
          item: "Women are born with a finite number of eggs, and as they age, both the quantity and quality of these eggs decrease. Diminished ovarian reserve can lead to lower success rates with IVF.",
        },
        {
          bold: "Chromosomal Abnormalities: ",
          item: "Older eggs are more likely to have chromosomal abnormalities, increasing the risk of miscarriage and genetic conditions in offspring. This factor can influence decisions regarding genetic testing of embryos during the IVF process.",
        },
        {
          bold: "Hormonal Changes: ",
          item: "As women age, hormonal fluctuations can affect ovulation, making it more challenging to conceive naturally or through assisted reproductive technologies like IVF.",
        },
      ],
    },
    L2: {
      liss2: [
        {
          bold: "Ages 20-34: ",
          item: "IVF success rates are around 40% to 50%.",
        },
        {
          bold: "Ages 35-37: ",
          item: "Success rates drop to approximately 30% to 40%.",
        },
        {
          bold: "Ages 38-40: ",
          item: "Success rates further decline to 20% to 30%.",
        },
        {
          bold: "Ages 41-42: ",
          item: "Success rates fall to about 10% to 15%.",
        },
        {
          bold: "Ages 43 and above: ",
          item: "The likelihood of success is typically less than 5%.",
        },
        
      ],
    },
    L3: {
      liss3: [
        {
          bold: "Health Status: ",
          item: "Overall health, including any pre-existing medical conditions, can affect fertility. Women with conditions like polycystic ovary syndrome (PCOS) or endometriosis may face additional challenges, and their optimal age for IVF may differ.",
        },
        {
          bold: "Lifestyle Choices: ",
          item: "Factors such as diet, exercise, smoking, and alcohol consumption can impact fertility. Maintaining a healthy lifestyle can improve IVF success rates, regardless of age.",
        },
        {
          bold: "Financial Considerations: ",
          item: "IVF can be costly, and many insurance plans do not cover the entire procedure. It’s essential to assess your financial situation and consider whether you can afford multiple cycles if necessary.",
        },
        {
          bold: "Emotional Readiness: ",
          item: "The emotional toll of infertility treatments can be significant. Ensure that you and your partner are emotionally prepared for the IVF process, as well as the potential challenges that may arise.",
        },
        {
          bold: "Partner's Age and Health: ",
          item: "If you have a partner, their age and health status should also be taken into account, as male fertility can influence overall success.",
        },
        
      ],
    },
    L4: {
      liss4: [
        {
          bold: "When to Consider Freezing Eggs: ",
          item: "Women in their late 20s to early 30s who are not ready to conceive but wish to preserve their fertility for future use can benefit from egg freezing. The earlier the eggs are frozen, the higher the likelihood of obtaining viable embryos later.",
        },
        {
          bold: "Consideration for Same-Sex Couples: ",
          item: (
            <>
              Couples in same-sex relationships may also consider fertility preservation or IVF with donor sperm to plan for future family building.

              <h4 className="mt-3">Choosing the Right Time to Begin IVF</h4>
            </>
          ),
        },
        {
          bold: "The choice of when to begin IVF",
          item: " is ultimately quite personal and should be chosen after giving careful thought to a number of variables. The following steps will assist in directing your decision-making process:",
        },
        {
          bold: "Speak with an Expert in Fertility: ",
          item: "Make an appointment for a consultation with a reproductive specialist to go over your unique circumstances, including age, health, and fertility issues.",
        },
        {
          bold: "Evaluate Your Choices: ",
          item: "Determine which reproductive alternatives are best for you based on your situation, such as beginning IVF right away, saving eggs for later use, or thinking about other family-building possibilities.",
        },
        
      ],
    },
  },

  Blog_4: {
    L1: {
      liss: [
        {
          bold: "AI in Embryo Selection: ",
          item: "AI algorithms can now assess embryo quality more precisely than traditional methods. By analyzing time-lapse images of embryos, AI can identify subtle developmental patterns and predict which embryos have the highest chance of leading to a successful pregnancy. This technology helps reduce subjectivity in embryo selection and increases implantation rates.",
        },
        {
          bold: "Predictive Analytics: ",
          item: "AI-powered predictive models can evaluate multiple factors, such as patient age, medical history, and hormone levels, to forecast the success of IVF cycles. This allows for a more personalized treatment approach, optimizing protocols for each individual patient.",
        },
      ],
    },
    L2: {
      liss2: [
        {
          bold: "Expanded PGT: ",
          item: "The latest versions of PGT offer broader genetic screening, allowing specialists to test for a wider range of genetic conditions beyond traditional chromosomal abnormalities. Expanded PGT can identify inherited diseases, such as cystic fibrosis or Tay-Sachs disease, ensuring that only healthy embryos are selected for transfer.",
        },
        {
          bold: "Non-Invasive Genetic Testing (niPGT): ",
          item: "One of the most anticipated innovations in 2024 is the development of non-invasive genetic testing methods. Traditional PGT involves taking a biopsy from the embryo, but niPGT uses DNA fragments from the culture media surrounding the embryo to conduct genetic screening. This non-invasive method reduces the risk of damaging the embryo while still providing valuable genetic information.",
        },
      ],
    },
    L3: {
      liss3: [
        {
          bold: "Preventing Inherited Diseases: ",
          item: "CRISPR technology can be used to edit out genetic mutations in embryos that may lead to serious inherited diseases. This approach could potentially prevent conditions such as Huntington's disease, muscular dystrophy, or certain cancers from being passed down to future generations.",
        },
        {
          bold: "Ethical Considerations: ",
          item: "While the possibilities of gene editing are exciting, they also raise significant ethical concerns. In 2024, regulatory bodies continue to discuss guidelines and restrictions on the use of CRISPR in IVF, balancing the potential benefits with ethical responsibilities.",
        },
      ],
    },
    L4: {
      liss4: [
        {
          bold: "Improved Embryo Viability Assessment: ",
          item: "Time-lapse imaging captures thousands of images of embryos at various stages of development. With more advanced image analysis software available in 2024, embryologists can make more accurate assessments of embryo health and choose the most viable ones for transfer.",
        },
        {
          bold: "Minimizing Human Error: ",
          item: "Traditional embryo assessments require manual observations, which can sometimes lead to errors or inconsistencies. Time-lapse imaging minimizes these risks, allowing for more objective and standardized monitoring of embryo development.",
        },
      ],
    },
    L5: {
      liss5: [
        {
          bold: "Robotic Egg Retrieval: ",
          item: "Robotic systems assist in egg retrieval by enhancing precision during the procedure. Robotic assistance reduces the risk of complications and increases the number of viable eggs collected, leading to better outcomes.",
        },
        {
          bold: "Micromanipulation in ICSI (Intracytoplasmic Sperm Injection): ",
          item: "ICSI is a technique where a single sperm is injected directly into an egg to aid fertilization. In 2024, robotic micromanipulation tools provide more accurate sperm injection, improving fertilization rates and minimizing the risk of damage to the egg.",
        },
      ],
    },
    L6: {
      liss6: [
        {
          bold: "Ovulation Monitoring: ",
          item: "Wearable devices can monitor a woman's ovulation cycle by tracking physiological indicators like body temperature, hormone levels, and sleep patterns. This data helps fertility specialists fine-tune treatment timing to maximize success.",
        },
        {
          bold: "Personalized Fertility Insights: ",
          item: "In 2024, wearable devices are equipped with advanced AI algorithms that provide personalized insights into fertility health. Patients can receive real-time feedback on how their lifestyle choices impact fertility and adjust their habits to improve IVF outcomes.",
        },
      ],
    },
    L7: {
      liss7: [
        {
          bold: "Vitrification: ",
          item: "Vitrification, a rapid-freezing method, has become the standard for preserving eggs and embryos. In 2024, advancements in vitrification technology are leading to even higher survival rates during thawing, ensuring that more frozen eggs and embryos remain viable for future use.",
        },
        {
          bold: "Fertility Preservation for Medical Reasons: ",
          item: "Individuals facing medical treatments that may compromise fertility, such as chemotherapy, can benefit from the improved success rates of egg and embryo freezing, ensuring that their future reproductive options are preserved.",
        },
      ],
    },
  },

  Blog_5: {
    L1: {
      liss: [
        {
          bold: "Anxiety and Stress: ",
          item: (
            <>
              A number of medical procedures, such as hormone injections, egg retrieval, and embryo transfer, are part of <Link to="/Programme/IVF" className="blogLink">IVF therapy. </Link>  The physical challenges and the unknown nature of the outcome frequently cause tension and anxiety to spike. Many are concerned about how they will handle failure, how many cycles they will require, and whether the treatment will be effective.
            </>
          ),
        },
        {
          bold: "Ups and downs in emotions: ",
          item: "Mood can be greatly impacted by the hormonal drugs used during in vitro fertilization. Hormone fluctuations can cause mood swings, irritation, or heightened sensitivity, which can cause stress in relationships and frustration.",
        },
        {
          bold: "Despondency: ",
          item: "Feelings of pessimism or depression can arise from repeated failures or extended intervals without periods. The psychological toll that infertility takes, particularly when paired with social demands.",
        },
        {
          bold: "Isolation Feelings: ",
          item: "Many people going through IVF could feel alone since they think other people can't truly get how difficult their experience is. People can isolate themselves even more from support systems by avoiding situations where the subject of children or pregnancy comes up at social events.",
        },
        {
          bold: "Stress in Relationships: ",
          item: "Love relationships may suffer as a result of the stress of reproductive treatments. Couples may have trouble expressing themselves, handling the financial strain of IVF, or handling varying emotional reactions to the procedure.",
        },
      ],
    },
    L2: {
      liss2: [
        {
          bold: "Enhancing Emotional Fortitude: ",
          item: "Support for mental health gives people and couples coping mechanisms to manage the emotional strain of in vitro fertilization. Throughout their therapy, patients can develop emotional resilience, improve their stress management skills, and hold onto hope with the correct kind of support.",
        },
        {
          bold: "Reducing Anxiety and Depression: ",
          item: "Therapies such as counseling and cognitive-behavioral therapy (CBT) are highly effective in reducing symptoms of anxiety and depression that commonly arise during IVF. Professional support can help individuals process their feelings and manage emotional responses more healthily.",
        },
        {
          bold: " Building Bonds with Others: ",
          item: (
            <>
              A number of medical procedures, such as hormone injections, egg retrieval, and embryo transfer, are part of <Link to="/Programme/IVF" className="blogLink">IVF therapy. </Link>  The physical challenges and the unknown nature of the outcome frequently cause tension and anxiety to spike. Many are concerned about how they will handle failure, how many cycles they will require, and whether the treatment will be effective.

              Couples in therapy can find a secure place to freely communicate their feelings, worries, and fears without fear of being judged. During the <Link to="/Programme/IVF" className="blogLink">IVF procedure, </Link>counseling can help partners communicate and understand one another better, which can reduce tension and strengthen the relationship.
            </>
          ),
        },
        {
          bold: "Improving the Success of IVF: ",
          item: "Although sperm count or egg quality are not directly impacted by mental health support, research indicates that lowering stress and anxiety may have a positive effect on reproductive outcomes. Less stressed-out women tend to conceive more frequently after IVF. A supported and serene mind can improve general health and foster a more conducive environment for conception.",
        },
      ],
    },
    L3: {
      liss3: [
        {
          bold: "Consult a professional counselor: ",
          item: "It can be quite helpful to work with a therapist or counselor who specializes in fertility concerns. A mental health specialist can offer a secure setting for talking about the psychological effects of infertility, controlling expectations, and creating coping strategies for the highs and lows of IVF.",
        },
        {
          bold: "Join a Support Group: ",
          item: "Connecting with others who are undergoing IVF can help alleviate feelings of isolation. Support groups offer a community of people who understand the unique challenges of fertility treatments, providing empathy, encouragement, and shared experiences.",
        },
         {
          bold: "Use relaxation and mindfulness practices: ",
          item: "Deep breathing exercises, yoga, and meditation are examples of mindfulness practices that can ease tension and encourage calm. By promoting present-moment awareness, mindfulness techniques can lessen worry about the future and how treatments will turn out.",
        },
        {
          bold: "Continue your lines of communication open with your partner: ",
          item: "IVF may test even the closest relationships, so communication is important. Couples should communicate their emotions, support one another, and check in on a regular basis. Couples therapy can be very helpful in resolving conflicts and overcoming emotional barriers that may arise throughout treatment.",
        },
        {
          bold: "Have Reasonable Expectations: ",
          item: "It's critical to understand that getting pregnant through IVF is not a given. People might lessen the disappointment of a failed effort by planning for the prospect of several cycles and setting realistic expectations. Professionals in mental health can assist patients in controlling their expectations and building resilience.",
        },
        {
          bold: "Take Breaks When Needed: ",
          item: "The IVF process can be all-consuming, but it's essential to take breaks and engage in activities that bring joy and relaxation. Whether it's spending time with loved ones, pursuing hobbies, or simply resting, taking time for self-care is crucial during the emotionally demanding IVF journey.",
        },
       
      ],
    },
    
  },

  Blog_6: {
    L1: {
      liss: [
        {
          bold: "Purpose:",
          item: "Egg freezing is a proactive step taken to preserve fertility for the future, while IVF is typically used as a solution when someone is actively trying to conceive. Egg freezing is about postponing the decision to start a family, whereas IVF is focused on immediate pregnancy.",
        },
        {
          bold: "Timeline: ",
          item: "Egg freezing allows you to store eggs and use them years later when you're ready. IVF, on the other hand, is typically performed with the intent of achieving pregnancy within a short period.",
        },
        {
          bold: "Success Rates: ",
          item: "Egg freezing success rates depend on several factors, especially age. Younger women have higher-quality eggs, which increases the chance of successful fertilization later. IVF success rates are similarly affected by age and the health of both the egg and sperm at the time of treatment.",
        },
      ],
    },
    L2: {
      liss2: [
        {
          bold: "Professional and Individual Objectives: ",
          item: "Many women decide to freeze their eggs in order to focus on other aspects of their lives, Such as their careers, education, or personal growth. You can put off having children without sacrificing your chance of being fertile if you freeze your eggs.",
        },
        {
          bold: "Preserving Fertility Before Medical Treatments: ",
          item: "Egg freezing is a common choice for women undergoing certain medical treatments, such as radiation or chemotherapy for cancer. Freezing eggs in advance gives them the chance to conceive later because these therapies can harm the ovaries and impair fertility.",
        },
        {
          bold: "Fertility and Age: ",
          item: "Women in their 20s or early 30s can retain their younger, healthier eggs for later use by freezing them. Fertility decreases with age. When you're ready, freezing eggs early increases the likelihood of a successful pregnancy.",
        },
        {
          bold: "Command of Timing: ",
          item: "Freezing eggs gives you flexibility. With this option, you can schedule your pregnancy according to your own schedule, regardless of whether you're waiting for the proper partner or are just not ready to become a parent.",
        },
        
      ],
    },

    L3: {
      liss3: [
        {
          bold: "immediate Family Formation: ",
          item: "When a couple or person is ready to create a family but is having trouble conceiving, IVF is the recommended course of action. IVF offers a way to conceive right away, regardless of the cause obstructed fallopian tubes, male infertility, or other reproductive issues.",
        },
        {
          bold: "Use of Donor Eggs or Sperm: ",
          item: "IVF allows the use of donor eggs, sperm, or embryos, making it a great option for same-sex couples, single individuals, or those facing infertility. Egg freezing, in contrast, only preserves your own eggs.",
        },
        {
          bold: "Success Rates: ",
          item: "IVF technology has improved significantly over the years. For women under the age of 35, the success rate of IVF with fresh embryos can be as high as 40%. While age is still a critical factor, IVF remains one of the most effective fertility treatments available today.",
        },
        {
          bold: "Overcoming Infertility Challenges: ",
          item: "IVF is designed to help with specific fertility issues. From polycystic ovary syndrome (PCOS) to unexplained infertility, IVF can address various problems that other treatments may not resolve.",
        },
        
      ],
    },

    L4: {
      liss4: [
        {
          bold: "Age and Fertility Health: ",
          item: "Age is one of the most critical factors to consider. If you're in your late 20s or early 30s and are not ready for children but want to preserve your fertility, egg freezing might be a better option. For women in their late 30s or 40s, IVF might offer a higher chance of success.",
        },
        {
          bold: "Costs: ",
          item: "Both egg freezing and IVF can be expensive. While egg freezing involves the cost of retrieval and storage, IVF includes egg retrieval, fertilization, embryo transfer, and potentially multiple cycles. Be sure to discuss costs with your fertility specialist and explore insurance or financing options.",
        },
        {
          bold: "Emotional Considerations: ",
          item: "Both treatments can be emotionally taxing. Egg freezing can bring peace of mind about future fertility, but IVF can be more intense because of the immediate goal of pregnancy. Emotional support from family, friends, or fertility counselors can make a big difference.",
        },
        {
          bold: "Medical Considerations: ",
          item: "Not everyone is a candidate for egg freezing or IVF. Health conditions such as low ovarian reserve or certain reproductive disorders may impact your eligibility. A fertility specialist will assess your medical history and perform tests to determine the best course of action.",
        },
      ],
    },
    
  },

  Blog_7: {
    L1: {
      liss: [
        {
          bold: "Age: ",
          item: "Fertility naturally declines with age, especially after 35 for women, which can impact the ability to conceive again.",
        },
        {
          bold: "Health Issues: ",
          item: "Conditions like polycystic ovary syndrome (PCOS), endometriosis, or uterine fibroids may develop or worsen over time.",
        },
        {
          bold: "Male Factor Infertility: ",
          item: "Changes in sperm quality or quantity can occur, leading to difficulty in conceiving.",
        },
        {
          bold: "Lifestyle Factors: ",
          item: "Weight gain, smoking, alcohol consumption, or stress can negatively impact fertility.",
        },
        {
          bold: "Complications from Previous Pregnancies: ",
          item: "Scarring or damage from a previous delivery (such as a C-section) can make conceiving again more difficult.",
        },
        
      ],
    },
    L2: {
      liss2: [
        {
          bold: "Age and Declining Egg Quality: ",
          item: "Women who had their first child at a younger age may now face age-related fertility issues. Egg quality diminishes as women age, which can reduce the chances of successful fertilization and implantation during IVF. In these cases, doctors may recommend additional interventions like preimplantation genetic testing (PGT) or the use of donor eggs.",
        },
        {
          bold: "Male Factor Infertility: ",
          item: "Even if a couple didn’t experience male infertility issues during their first pregnancy, it’s possible for sperm quality to decrease over time. IVF treatments can be tailored to address male factor infertility, including intracytoplasmic sperm injection (ICSI), where a single sperm is injected directly into an egg to improve the chances of fertilization.",
        },
        {
          bold: "Unresolved Health Issues: ",
          item: "Conditions like PCOS, endometriosis, or hormonal imbalances may go undiagnosed or become more severe over time. These issues can affect the outcome of IVF. Addressing underlying health conditions is crucial to improving fertility and optimizing IVF success. ",
        },
        {
          bold: "Increased Emotional Pressure: ",
          item: "Going through IVF while already raising children can add another layer of stress. Balancing the physical and emotional demands of IVF with parenting can be challenging. Couples may feel the pressure to achieve a successful outcome quickly, which can add to the stress.",
        },
        
      ],
    },
    L3: {
      liss3: [
        {
          bold: " Consult with a Specialist: ",
          item: "Consulting with a reproductive physician who understands the subtleties of secondary infertility is essential. Your doctor can design a customized treatment plan for you based on your medical history, current health, and past reproductive success.",
        },
        {
          bold: "Take Care of the Basis Conditions: ",
          item: (
            <>
            Before <Link to="/Programme/IVF" className="blogLink">beginning IVF, </Link>it's critical to take care of any health conditions that might be causing secondary infertility. Being proactive will increase the chances of success with IVF, whether it is for treating male reproductive disorders, uterine fibroids, or Pcos.
            </>
          ),
        },
        {
          bold: "Consider Advanced IVF Techniques: ",
          item: "Depending on the underlying cause of infertility, advanced techniques like preimplantation genetic testing (PGT) or using donor eggs or sperm may be recommended. PGT can help identify chromosomal abnormalities that could lead to failed pregnancies or miscarriages, while donor eggs may be suggested if egg quality is significantly diminished due to age.",
        },
        {
          bold: "Manage Emotional Well-Being: ",
          item: "The emotional toll of IVF and secondary infertility can be heavy. Couples should seek support from a fertility counselor or therapist who specializes in infertility issues. Having a safe space to talk about the fears, frustrations, and disappointments that arise during this journey can make a huge difference in managing the stress of treatment.",
        },
        {
          bold: "Be Patient with the Process: ",
          item: "IVF is not a guaranteed success, and it often takes more than one cycle to achieve a pregnancy. While it can be discouraging, it’s important to remember that each cycle provides more information about your fertility health, and adjustments can be made along the way. Staying patient and hopeful is key, even when the process feels long and challenging.",
        },
        {
          bold: "Communicate with Your Partner: ",
          item: "Navigating secondary infertility can put a strain on relationships. Make sure to keep communication open and honest with your partner. Share your feelings, fears, and hopes as you go through IVF together. Mutual support can help you stay connected and focused during this emotionally taxing time."
        }
      ],
    },
    
  },

  Blog_9: {
    L1: {
      liss: [
        {
          bold: "Under 35 years: ",
          item: "40% to 45% success rate",
        },
        {
          bold: "Ages 35-37: ",
          item: "30% to 40% success rate",
        },
        {
          bold: "Ages 38-40: ",
          item: "20% to 30% success rate",
        },
        {
          bold: "Over 40 years: ",
          item: "10% to 15% success rate",
        },
      ],
    },
    L2: {
      liss2: [
        {
          bold: "Age of the Woman: ",
          item: "As previously mentioned, a woman's age is the most critical factor in IVF success. The quality of eggs declines with age, affecting fertilization rates and embryo viability.",
        },
        {
          bold: "Cause of Infertility: ",
          item: "Different infertility issues, such as ovulatory disorders, endometriosis, or male factor infertility, can impact success rates. Understanding the specific cause can help tailor the treatment approach.",
        },
        {
          bold: "Embryo Quality: ",
          item: "The quality of embryos plays a crucial role in the chances of implantation. Higher-quality embryos have a better likelihood of leading to a successful pregnancy.",
        },
        {
          bold: "Number of Embryos Transferred: ",
          item: "Transferring multiple embryos can increase the chances of success, but it also raises the risk of multiple pregnancies, which can complicate health outcomes.",
        },
        {
          bold: "Fertility Clinic Experience: ",
          item: "The expertise and technology available at a fertility clinic can significantly impact success rates. Clinics with experienced professionals and advanced technology tend to have higher success rates.",
        },
        {
          bold: "Lifestyle Factors: ",
          item: "Factors such as smoking, obesity, and alcohol consumption can negatively affect fertility and the likelihood of IVF success. Couples are encouraged to adopt healthier lifestyles to improve their chances.",
        },
      ],
    },
    L3: {
      liss3: [
        {
          bold: "Choose a Reputable Fertility Clinic: ",
          item: "Research clinics with high success rates and positive patient reviews. Consider their experience, technology, and the range of services offered.",
        },
        {
          bold: "Maintain a Healthy Lifestyle: ",
          item: "Adopting a healthy diet, regular exercise, and avoiding harmful substances can improve fertility. Both partners should aim to be in optimal health before starting IVF.",
        },
        {
          bold: "Stay Informed: ",
          item: "Educate yourself about the IVF process, including potential challenges and success rates. Understanding what to expect can help couples feel more prepared.",
        },
        {
          bold: "Communicate Openly: ",
          item: "Discuss feelings and concerns with your partner throughout the IVF journey. Open communication can help alleviate stress and strengthen your relationship.",
        },
        {
          bold: "Consider Counseling: ",
          item: "Many couples benefit from counseling or support groups during the IVF process. This support can provide coping strategies and a safe space to express feelings.",
        },
      ],
    },
  },

  Blog_10: {
    L1: {
      liss: [
        {
          bold: "Irregular Menstrual Cycles: ",
          item: "Women with PCOS often experience infrequent or prolonged menstrual periods, which can make ovulation unpredictable.",
        },
        {
          bold: "Excess Androgens: ",
          item: "Elevated levels of male hormones, such as testosterone, can lead to symptoms like acne, excess facial or body hair (hirsutism), and thinning hair on the scalp.",
        },
        {
          bold: "Polycystic Ovaries: ",
          item: "Many women with PCOS have enlarged ovaries containing multiple small cysts, which can be detected through ultrasound.",
        },
      
      ],
    },
    L2: {
      liss2: [
        {
          bold: "Irregular Ovulation: ",
          item: "One of the most significant effects of PCOS on fertility is irregular or absent ovulation. Without regular ovulation, conception becomes challenging. Many women with PCOS may experience anovulation (failure to ovulate), making it difficult to predict fertile windows.",
        },
        {
          bold: "Hormonal Imbalances: ",
          item: "The hormonal imbalances associated with PCOS can disrupt the delicate interplay between hormones necessary for ovulation and pregnancy. Elevated insulin levels can also contribute to weight gain and metabolic issues, further complicating fertility.",
        },
        {
          bold: "Increased Risk of Miscarriage: ",
          item: <>
            Women with <Link to="/" className="blogLink">PCOS </Link> may face a higher risk of miscarriage due to hormonal imbalances, insulin resistance, and other factors. Understanding these risks is essential for those trying to conceive.
          </>,
        },
        {
          bold: "Associated Health Conditions: ",
          item: "PCOS is often linked with other health conditions that can impact fertility, including obesity, type 2 diabetes, and cardiovascular issues. These conditions can complicate pregnancy and affect overall health.",
        },
        
      ]
    },
    L3: {
      liss3: [
        {
          bold: "Irregular or absent menstrual cycles",
          item: "",
        },
        {
          bold: "Signs of excess androgens ",
          item: "(e.g., hirsutism, acne)",
        },
        {
          bold: "Polycystic ovaries visible on ultrasound",
          item: "",
        },
        
      ],
    },
    L4: {
      liss4: [
        {
          bold: "Lifestyle Modifications: ",
          item: "For many women with PCOS, lifestyle changes can significantly impact fertility. Maintaining a healthy weight through balanced nutrition and regular exercise can help regulate menstrual cycles, improve insulin sensitivity, and enhance ovulation."
        },
        {
          bold: "Medications: ",
          item: <>
              <ul>
                  <li>
                    <i class="bx bx-right-arrow-circle"></i>
                    <b>Clomiphene Citrate: </b>
                    Often the first-line treatment for inducing ovulation in women with PCOS, clomiphene works by stimulating the ovaries to produce eggs.
                  </li>
                  <li>
                    <i class="bx bx-right-arrow-circle"></i>
                    <b>Metformin: </b>
                    This medication is commonly used to treat insulin resistance associated with PCOS. It can help regulate menstrual cycles and improve ovulation rates.
                  </li>
                  <li>
                    <i class="bx bx-right-arrow-circle"></i>
                    <b>Letrozole: </b>
                    An alternative to clomiphene, letrozole is used to induce ovulation and has shown effectiveness in women with PCOS.
                  </li>
                  <li>
                    <i class="bx bx-right-arrow-circle"></i>
                    <b>Gonadotropins: </b>
                    For those who do not respond to oral medications, injectable hormone treatments can stimulate the ovaries to produce eggs.
                  </li>
              </ul>
          </>
        },
        {
          bold: "Assisted Reproductive Technologies (ART): ",
          item: <>
          If other treatments are unsuccessful, assisted reproductive technologies such as In Vitro Fertilization (IVF) may be recommended. <Link to="/Programme/IVF" className="blogLink">IVF </Link>allows for greater control over the fertilization process and can increase the chances of conception.
          </>
        },
        {
          bold: "Surgery: ",
          item: "In certain cases, ovarian drilling may be considered. This laparoscopic procedure involves making small punctures in the ovaries to reduce androgen production and stimulate ovulation. However, it is typically reserved for women who do not respond to other treatments."
        },
        {
          bold: "Managing Associated Conditions: ",
          item: "Addressing related health issues, such as obesity or diabetes, can improve overall health and enhance fertility. Collaborating with healthcare providers to manage these conditions is essential for women with PCOS."
        }
      ]
    }

  },

  Blog_11: {
    L1: {
      liss: [
        {
          bold: "Improved Timing: ",
          item: "After ovarian stimulation, patients may not feel ready for an immediate transfer. FET allows time for the body to recover before proceeding with embryo transfer.",
        },
        {
          bold: "Multiple Chances of Pregnancy: ",
          item: "With FET, any embryos not used during a fresh IVF cycle can be frozen, stored, and used later, providing additional opportunities for pregnancy without the need for repeat ovarian stimulation.",
        },
        {
          bold: "Reduced Risks of Ovarian Hyperstimulation Syndrome (OHSS): ",
          item: "In certain cases, a “freeze-all” approach is used to reduce the risk of OHSS, a condition that can occur after hormone stimulation.",
        },
        {
          bold: "Genetic Screening Options: ",
          item: "FET also allows time for preimplantation genetic testing (PGT), which screens embryos for genetic abnormalities before transfer. This can improve the likelihood of a healthy pregnancy.",
        },
        
      ],
    },
    L2: {
      liss2: [
        {
          bold: "Prepare the Cycle: ",
          item: "Patients go through cycle preparation prior to the frozen embryo transfer. In order to get the uterine lining ready for embryo implantation, this may entail hormone therapies. For the embryo to successfully connect, the endometrium, the lining of the uterus, must be at its ideal thickness. Natural Cycle FET: To identify the ideal timing for transfer, natural hormone levels are tracked in women who have regular menstrual cycles. Hormone Replacement Therapy (HRT) Cycle FET: HRT, which includes progesterone and estrogen, is used to regulate the timing and guarantee the ideal thickness of the uterine lining in women with irregular cycles.",
        },
        {
          bold: "Monitoring: ",
          item: "Blood tests and ultrasounds are conducted to check hormone levels and endometrial thickness. Once the lining reaches an ideal thickness, the transfer date is scheduled.",
        },
        {
          bold: "Warming:  ",
          item: "On the day of the transfer, the embryos are carefully thawed. Cryopreservation advancements have made it possible for embryos to survive the freezing and thawing process with high success rates.",
        },
        {
          bold: " Embryo Transfer: ",
          item: <>
            The <Link to="/Programme/EmbryoTransfer" className="blogLink">embryo </Link>  is transferred to the uterus using a thin catheter. This is a minimally invasive procedure performed under ultrasound guidance to ensure accurate placement. Most women find the transfer process to be straightforward and minimally uncomfortable.
          </>,
        },
        {
          bold: "Post-Transfer Care",
          item: "After the transfer, patients may be advised to rest and take medications, such as progesterone, to support implantation. The “two-week wait” begins, leading up to a pregnancy test to confirm successful implantation.",
        },
        
      ],
    },
    L3: {
      liss3: [
        {
          bold: "Increased Flexibility: ",
          item: "Patients and doctors can select a time when the body is hormonally balanced and physically ready for transfer.",
        },
        {
          bold: "Higher Success Rates: ",
          item: "Some studies suggest that FET cycles have higher success rates, as the body has time to return to a natural hormonal state post-stimulation, creating a more favorable environment for implantation."
        },
        {
          bold: "Fewer Risks of Complications: ",
          item: "FET reduces risks associated with high hormone levels post-stimulation, making it safer for patients prone to complications.",
        },
        {
          bold: "Simplicity and Efficiency: ",
          item: "The FET process is often more straightforward than fresh embryo transfer, with fewer required medications and a shorter preparation time.",
        },
        {
          bold: "FET Statistics and Success Rates",
          item: "Thanks to developments in freezing techniques like vitrification, a quick-freezing procedure that reduces the creation of ice crystals, FET success rates have been continuously increasing. FET cycles for women under 35 can result in live birth rates of more than 50%, while success rates can vary depending on age, embryo quality, and other factors. FET provides many patients with a very successful way to become pregnant.",
        },
      ],
    },
    L4: {
      liss4: [
        {
          bold: "Multiple Pregnancies: ",
          item: "When more than one embryo is transferred, there is a higher chance of twins or triplets, which can increase pregnancy risks.",
        },
        {
          bold: "Failed Implantation: ",
          item: "There is always a possibility that the embryo will not implant, even if all conditions are optimal.",
        },
        {
          bold: "Emotional Impact: ",
          item: "The emotional toll of waiting and potential outcomes can be challenging. Many patients find support through counseling, fertility support groups, or therapy beneficial.",
        },
        
      ],
    },
    L5: {
      liss5: [
        {
          bold: "Healthy Lifestyle: ",
          item: "Maintaining a balanced diet, moderate exercise, and stress management can help improve the likelihood of a successful outcome.",
        },
        {
          bold: "Adherence to Medication: ",
          item: "Follow all medication guidelines provided by your healthcare team to support the body’s readiness for implantation.",
        },
        {
          bold: "Support System: ",
          item: "Emotional and psychological support during this time can ease stress and contribute to a positive experience. Whether through family, friends, or professional counseling, having a support network can be invaluable.",
        },
        
      ],
    }
  },
};

export default ListContent;
